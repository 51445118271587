import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ListSubheader,
  Box,
} from "@mui/material";
import useGetListOf from "../../hooks/useGetListOf";

const PromotionsTypeListFilter = ({ onTypeSet, searchParams, variant }) => {
  const typologiesList = useGetListOf(
    "Promotion_Typologies",
    "order_key=order",
    "list"
  );
  const [selectedType, setSelectedType] = useState("");
  const [open, setOpen] = useState(false);

  const handleChange = (value) => {
    if (value === "Altro") {setSelectedType(value); onTypeSet("", "", value)}
    else {
      console.log(value.description);
      setSelectedType(value.description);
      onTypeSet(value.id, "typology_id", value.description);
    }
  };

  useEffect(() => {
    if (searchParams.size === 0) setSelectedType("");
  }, [searchParams]);

  // Funzione per suddividere gli elementi in gruppi
  const groupTypologies = () => {
    const grouped = {
      firstGroup: [],
      alvita: [],
      espositori: [],
      formazione: [],
      servizi: [],
      altre: [],
      lastGroup: [],
    };

    typologiesList.listElements.forEach((type) => {
      if (type.description.includes("Alvita")) {
        grouped.alvita.push(type);
      } else if (type.description.includes("Espositori")) {
        grouped.espositori.push(type);
      } else if (type.description.includes("Formazione")) {
        grouped.formazione.push(type);
      } else if (type.description.includes("Servizi")) {
        grouped.servizi.push(type);
      } else if (
        type.description.includes("Campagne") ||
        type.description.includes("Planogrammi") ||
        type.description.includes("Volantino") ||
        type.description.includes("Carta")
      ) {
        grouped.firstGroup.push(type);
      } else if (
        type.description.includes("Tavole") ||
        type.description.includes("Calendari")
      ) {
        grouped.lastGroup.push(type);
      } else {
        grouped.altre.push(type);
      }
    });

    return grouped;
  };

  const groupedTypologies = typologiesList ? groupTypologies() : {};

  return (
    <>
      {typologiesList && typologiesList.length !== 0 && (
        <FormControl size="small" sx={{ width: "100%" }}>
          <InputLabel>Categoria comunicazione</InputLabel>
          <Select
            value={selectedType}
            label="Categoria comunicazione"
            onChange={(e) => handleChange(e.target.value)}
            open={open}
            onClick={(e) => setOpen(!open)}
            renderValue={(value) => <>{value}</>}
          >
            <MenuItem value="">-</MenuItem>
            {variant === "photo-archive" && groupedTypologies.altre.length > 0 && (
              <>
                {groupedTypologies.altre.map((type) => (
                  <MenuItem
                    key={type.id}
                    value={type.id}
                    onClick={() => handleChange(type)}
                  >
                    {type.description}
                  </MenuItem>
                ))}
              </>
            )}
            {groupedTypologies.firstGroup.length > 0 && (
              <>
                {groupedTypologies.firstGroup.map((type) => (
                  <MenuItem
                    key={type.id}
                    value={type.id}
                    onClick={() => handleChange(type)}
                  >
                    {type.description}
                  </MenuItem>
                ))}
              </>
            )}
            {groupedTypologies.espositori.length > 0 && (
              <>
                <ListSubheader sx={{ background: "#ddd", mt: 1 }}>
                  <b>ESPOSITORI</b>
                </ListSubheader>
                {groupedTypologies.espositori.map((type) => (
                  <MenuItem
                    key={type.id}
                    value={type.id}
                    sx={{ ml: 2 }}
                    onClick={() => handleChange(type)}
                  >
                    {type.description}
                  </MenuItem>
                ))}
              </>
            )}
            {groupedTypologies.alvita.length > 0 && (
              <>
                <ListSubheader sx={{ background: "#ddd", mt: 1 }}>
                  <b>ALVITA</b>
                </ListSubheader>
                {groupedTypologies.alvita.map((type) => (
                  <MenuItem
                    key={type.id}
                    value={type.id}
                    sx={{ ml: 2 }}
                    onClick={() => handleChange(type)}
                  >
                    {type.description}
                  </MenuItem>
                ))}
              </>
            )}
            {groupedTypologies.servizi.length > 0 && (
              <>
                <ListSubheader sx={{ background: "#ddd", mt: 1 }}>
                  <b>SERVIZI</b>
                </ListSubheader>
                {groupedTypologies.servizi.map((type) => (
                  <MenuItem
                    key={type.id}
                    value={type.id}
                    sx={{ ml: 2 }}
                    onClick={() => handleChange(type)}
                  >
                    {type.description}
                  </MenuItem>
                ))}
              </>
            )}
            {groupedTypologies.espositori.length > 0 && (
              <>
                <ListSubheader sx={{ background: "#ddd", mt: 1 }}>
                  <b>FORMAZIONE</b>
                </ListSubheader>
                {groupedTypologies.formazione.map((type) => (
                  <MenuItem
                    key={type.id}
                    value={type.id}
                    sx={{ ml: 2 }}
                    onClick={() => handleChange(type)}
                  >
                    {type.description}
                  </MenuItem>
                ))}
              </>
            )}
            {groupedTypologies.lastGroup.length > 0 && (
              <Box sx={{ mt: 1 }}>
                {groupedTypologies.lastGroup.map((type) => (
                  <MenuItem
                    key={type.id}
                    value={type.id}
                    onClick={() => handleChange(type)}
                  >
                    {type.description}
                  </MenuItem>
                ))}
              </Box>
            )}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default PromotionsTypeListFilter;
