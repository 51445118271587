import React from "react";

import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import { Button, Grid2, Paper, Typography } from "@mui/material";

import NavBar from "../../../components/navbar/NavBar";
import useGetCurrentUserData from "../../../hooks/auth/useGetCurrentUserData";
import LogoutBtn from "../../../components/navbar/buttons/singleButtons/LogoutBtn";

const PharmaciesHome = () => {
  const navigate = useNavigate();
  const userData = useGetCurrentUserData();

  return (
    <>
      <NavBar type="8632" />
      <Typography component="h1" variant="h5" sx={{ m: 3 }}>
        Benvenuto
        <br />
        {userData.business_name}
      </Typography>
      <Container component={Paper} maxWidth="sm" sx={{ p: 2 }}>
        <Grid2>
          <Button
            fullWidth
            onClick={() => navigate("/pharmacies_promotions")}
            variant="contained"
            sx={{ mt: 3, mb: 3 }}
          >
            Le mie comunicazioni
          </Button>
          <Button
            fullWidth
            onClick={() => navigate("/pharmacies_upload")}
            variant="contained"
          >
            Carica foto
          </Button>
          <Button
            fullWidth
            onClick={() => navigate("/profile")}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Profilo
          </Button>
          <LogoutBtn />
        </Grid2>
      </Container>
    </>
  );
};

export default PharmaciesHome;
