import React from "react";

import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import { Button, Grid2, Paper, Typography } from "@mui/material";

import NavBar from "../../../components/navbar/NavBar";
import LogoutBtn from "../../../components/navbar/buttons/singleButtons/LogoutBtn";

const AlphegaHome = () => {
  const navigate = useNavigate();

  return (
    <>
      <NavBar type="4719" />

      {/* <LogoPublic /> */}
      <Typography component="h1" variant="h5" sx={{ m: 3 }}>
        Benvenuto
        <br />
        Alphega Centrale
      </Typography>
      <Container component={Paper} maxWidth="sm" sx={{ p: 2 }}>
        <Grid2>
          <Button
            fullWidth
            onClick={() => navigate("/alphega_centrale/alphega_promotions")}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Tutte le comunicazioni
          </Button>
          <Button
            fullWidth
            onClick={() => navigate("/alphega_centrale/alphega_users")}
            variant="contained"
            sx={{ mt: 1, mb: 2 }}
          >
            Tutte le farmacie
          </Button>
          <Button
            fullWidth
            onClick={() => navigate("/alphega_centrale/alphega_create_promotion")}
            variant="contained"
            sx={{ mt: 1, mb: 2 }}
          >
            Crea comunicazione
          </Button>
          <Button
            fullWidth
            onClick={() => navigate("/alphega_centrale/files")}
            variant="contained"
            sx={{ mt: 1, mb: 2 }}
          >
            Archivio files
          </Button>
          <LogoutBtn />
        </Grid2>
      </Container>
    </>
  );
};

export default AlphegaHome;
