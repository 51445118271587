import React from "react";
import ElementsList from "../../ElementsList";

const UsersDetailPromotions = ({ userId }) => {
  return (
    <ElementsList
      type="Promotion_Assignments"
      variantSearchKeys={`where_key=user_id&where_value=${userId}&complete=true`}
    />
  );
};

export default UsersDetailPromotions;
