import React from "react";
import useGetListOf from "../../hooks/useGetListOf";
import FilesDataDisplay from "./FilesDataDisplay";
const Files = () => {
  const photoData = useGetListOf("Files", "", "list");
  const promotionsData = useGetListOf("Promotions", "", "list");

  const pharmaciesData = useGetListOf(
    "Users",
    "where_key=roles.value&where_value=Farmacia&complete=true",
    "list"
  );
  return (
    <FilesDataDisplay photoData={photoData} promotionsData={promotionsData} pharmaciesData={pharmaciesData}/>
  );
};

export default Files;
