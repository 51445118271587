import {
  Box,
  Button,
  Input,
  MenuItem,
  Modal,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useGetPromotionTypes from "../../hooks/promotions/useGetPromotionTypes";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile } from "react-device-detect";
import { axiosPrivate } from "../../api/axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";

const PromotionsModifyModal = ({
  promotionId,
  open,
  onClose,
  title,
  description,
  type,
  startDate,
  endDate,
  file,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [newTitle, setNewTitle] = useState(null);
  const [newDescription, setNewDescription] = useState(null);
  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);
  const [newType, setNewType] = useState(null);
  const [value, setValue] = useState(0);

  const [cover, setCover] = useState([]);
  const [attachment, setAttachment] = useState([]);

  useEffect(() => {
    if (file && file.length > 0) {
      const filteredCover = file.filter(
        (singleFile) => singleFile.file_type_id === 3
      );
      setCover(filteredCover);

      const filteredAttachments = file.filter(
        (singleFile) =>
          singleFile.file_type_id === 1 ||
          singleFile.file_type_id === 2 ||
          singleFile.file_type_id === 4
      );
      setAttachment(filteredAttachments);
    }
  }, [file]);

  const navigate = useNavigate();

  const promotionTypes = useGetPromotionTypes();

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const handleModifies = async () => {
    let modifies = {};

    if (newTitle !== null && newTitle !== title) {
      modifies.title = newTitle;
    }
    if (newDescription !== null && newDescription !== description) {
      modifies.description = newDescription;
    }
    if (newStartDate !== null && newStartDate !== startDate) {
      const formattedStartDate = dayjs(newStartDate).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      ); // NON CAMBIARE MAI
      modifies.start = formattedStartDate;
    }
    if (newEndDate !== null && newEndDate !== endDate) {
      const formattedEndDate = dayjs(newEndDate).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      ); // NON CAMBIARE MAI
      modifies.end = formattedEndDate;
    }
    if (newType !== null && newType !== type) {
      modifies.type = newType;
    }

    try {
      if (Object.keys(modifies).length !== 0) {
        const upload = await axiosPrivate.put("/Promotions/update", {
          id: promotionId,
          new_values: modifies,
        });
        if (upload) {
          window.alert("Modifiche effettuate con successo!");
          navigate(0);
        }
      } else window.alert("Non sono state effettuate modifiche");
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  const deleteFile = async (id, type) => {
    if (type === "cover") {
      console.log(cover);
      setCover([]);
    } else if (type === "attachment") {
      setAttachment((prevAttachments) =>
        prevAttachments.filter((file) => file.id !== id)
      );
    }
    try {
      const deleted = await axiosPrivate.delete(
        `/Promotion_Attachments/delete_with_file?id=${id}`
      );
      if (deleted) {
        if (type === "cover") navigate(0);
        else setSearchParams("");
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  const handleFileUpload = async (e, type) => {
    try {
      let fileTypeId;
      const file = e.target.files[0];
      const mimeType = file.type;
      if (type === "cover") {
        fileTypeId = 3;
      } else if (type === "attachment") {
        if (mimeType.startsWith("image/")) {
          fileTypeId = 1;
        } else if (
          mimeType === "application/pdf" ||
          mimeType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          mimeType ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          mimeType === "application/vnd.ms-excel" ||
          mimeType ===
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ) {
          fileTypeId = 2;
        } else {
          fileTypeId = 4;
        }
      }
      const formData = new FormData();
      formData.append("promotion_id", promotionId);
      formData.append("file_type_id", fileTypeId);
      formData.append("file", file);

      const upload = await axiosPrivate.post(
        "/Promotion_Attachments/create_with_file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (upload) {
        setSearchParams("");
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "85%",
          height: isMobile ? "90%" : "95%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 1,
          overflowY: "auto",
          maxHeight: isMobile ? "90%" : "95%",
        }}
      >
        <Box display={"flex"} justifyContent={"right"}>
          <Button
            onClick={handleClose}
            sx={{
              maxWidth: "30px",
              maxHeight: "30px",
              minWidth: "30px",
              minHeight: "30px",
            }}
          >
            <CloseIcon color="error" />
          </Button>
        </Box>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab
            label={
              <Typography variant="h6" fontSize={15}>
                MODIFICA DETTAGLI PROMOZIONE
              </Typography>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Typography variant="h6" fontSize={15}>
                MODIFICA COVER E FILE ALLEGATI
              </Typography>
            }
            {...a11yProps(1)}
          />
        </Tabs>

        <CustomTabPanel value={value} index={0}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ mb: 3 }}
          >
            <Typography variant="h5" sx={{ mt: 3 }}>
              Modifica la comunicazione e poi clicca il pulsante per salvare le
              modifiche
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Titolo della comunicazione"
              defaultValue={title}
              onChange={(e) => setNewTitle(e.target.value)}
              style={{ marginTop: 8 }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={2}
              label="Descrizione della comunicazione"
              defaultValue={description}
              onChange={(e) => setNewDescription(e.target.value)}
              style={{ marginTop: 8 }}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={isMobile ? "column" : "block"}
            alignItems={"center"}
            sx={{ mb: 2 }}
          >
            <Typography sx={{ mr: 3 }}>
              Seleziona la tipologia di comunicazione:
            </Typography>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              onChange={(e) => setNewType(e.target.value)}
              defaultValue={type}
            >
              {promotionTypes &&
                promotionTypes.map((promotionType, index) => (
                  <MenuItem key={index} value={promotionType.id}>
                    {promotionType.description}
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <Box
            display={"flex"}
            flexDirection={isMobile ? "column" : "block"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ mb: 2 }}
          >
            <Typography variant="body1" sx={{ mr: 3 }}>
              Nuova data inizio comunicazione:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                ampm={false}
                defaultValue={dayjs(startDate)}
                format="DD/MM/YYYY HH:mm"
                onChange={(date) => {
                  setNewStartDate(date);
                }}
              />
            </LocalizationProvider>
          </Box>

          <Box
            display={"flex"}
            flexDirection={isMobile ? "column" : "block"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ mb: 1 }}
          >
            <Typography variant="body1" sx={{ mr: 3 }}>
              Nuova data fine comunicazione:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                minDateTime={
                  newStartDate ? newStartDate : dayjs(startDate + "T00:00")
                }
                defaultValue={dayjs(endDate)}
                ampm={false}
                format="DD/MM/YYYY HH:mm"
                onChange={(date) => {
                  setNewEndDate(date);
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            display={"flex"}
            alignContent={"center"}
            justifyContent={"center"}
            sx={{ mt: 1 }}
          >
            <Button variant="contained" onClick={handleModifies}>
              Salva modifiche
            </Button>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Modifica aggiungendo o togliendo file
          </Typography>

          {cover.length !== 0 || attachment.length !== 0 ? (
            <TableContainer>
              <Table sx={{ width: "100%" }} aria-label="cover table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "30%" }}>Nome file</TableCell>
                    <TableCell align="right">Tipologia</TableCell>
                    <TableCell sx={{ width: "40%" }} align="right">
                      Azione
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cover.length !== 0 && (
                    <TableRow key={cover[0].id}>
                      <TableCell>{cover[0].title}</TableCell>
                      <TableCell align="right">COVER</TableCell>
                      <TableCell align="right">
                        <Button
                          color={"error"}
                          onClick={() => deleteFile(cover[0].id, "cover")}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}

                  {attachment.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.title}</TableCell>
                      <TableCell align="right">FILE ALLEGATO</TableCell>
                      <TableCell align="right">
                        <Button
                          color={"error"}
                          onClick={() => deleteFile(row.id, "attachment")}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>
              Al momento non ci sono file allegati per questa promozione
            </Typography>
          )}

          <Box
            display={"flex"}
            justifyContent={"space-evenly"}
            flexDirection={isMobile ? "column" : "block"}
            sx={{ mt: 3 }}
          >
            <Input
              id="cover-file-input"
              type="file"
              onChange={(e) => handleFileUpload(e, "cover")}
              disabled={cover.length}
              style={{ display: "none" }}
              inputProps={{
                accept: "image/png, image/jpeg",
              }}
            />
            <label htmlFor="cover-file-input">
              <Button
                variant="outlined"
                component="span"
                sx={{
                  width: isMobile ? "100%" : "325px",
                  mb: isMobile ? 1 : 0,
                }}
                disabled={cover.length}
              >
                CARICA FOTO COVER
              </Button>
            </label>

            <Input
              id="attachment-file-input"
              type="file"
              onChange={(e) => handleFileUpload(e, "attachment")}
              disabled={attachment.length === 3}
              style={{ display: "none" }}
              inputProps={{
                accept:
                  "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.presentationml.presentation",
              }}
            />
            <label htmlFor="attachment-file-input">
              <Button
                variant="outlined"
                component="span"
                sx={{
                  width: isMobile ? "100%" : "325px",
                  mb: isMobile ? 1 : 0,
                }}
                disabled={attachment.length === 3}
              >
                {attachment.length === 3
                  ? "MASSIMO DI 3 FILE RAGGIUNTO"
                  : "ALLEGATI"}
              </Button>
            </label>
          </Box>
        </CustomTabPanel>
      </Box>
    </Modal>
  );
};
export default PromotionsModifyModal;
