import { Button } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const AlphegaFilesBtn = () => {
  const navigate = useNavigate();

  return (
    <Button
      fullWidth={isMobile || (window.innerWidth <= 785 && true)}
      onClick={() => navigate("/alphega_centrale/files")}
    >
      ARCHIVIO FOTO
    </Button>
  );
};

export default AlphegaFilesBtn;
