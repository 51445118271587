import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";

const MultiCheckbox = ({ componentId, saveComponent }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([]);
  const [optionInput, setOptionInput] = useState("");
  const [obligatory, setObligatory] = useState(false);

  const handleAddOption = () => {
    if (optionInput.trim() !== "") {
      setOptions([...options, optionInput.trim()]);
      setOptionInput("");
    }
  };

  const handleRemoveOption = (optionToRemove) => {
    setOptions(options.filter((option) => option !== optionToRemove));
  };

  const handleSave = () => {
    saveComponent(componentId, title, description, options, obligatory);
  };

  useEffect(() => {
    handleSave();
  }, [title, description, options, obligatory]);

  return (
    <>
      <Typography variant="h5">Checkbox Multipla</Typography>
      Consente la selezione di più opzioni
      <Box>
        <TextField
          fullWidth
          label="Titolo"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ marginTop: 8 }}
        />
        <TextField
          fullWidth
          label="Descrizione"
          multiline
          rows={2}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ marginTop: 16 }}
        />
      </Box>
      <Box>
        <FormControl component="fieldset">
          <FormLabel component="legend"></FormLabel>
          <List>
            {options.map((option, index) => (
              <Box key={index} display="flex" alignItems="center" mb={1}>
                <FormControlLabel
                  control={<Checkbox value={option} disabled />}
                  label={option}
                />
                <Button
                  onClick={() => handleRemoveOption(option)}
                  color="error"
                  style={{ marginLeft: 8 }}
                >
                  Rimuovi scelta
                </Button>
              </Box>
            ))}
          </List>
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          fullWidth
          label="Aggiungi una scelta"
          value={optionInput}
          onChange={(e) => setOptionInput(e.target.value)}
          style={{ marginTop: 16 }}
          InputProps={{
            endAdornment: <Button onClick={handleAddOption}>Aggiungi</Button>,
          }}
        />
      </Box>
      <Typography>È obbligatorio rispondere a questa domanda?</Typography>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Typography>No</Typography>
        <Switch onChange={(e) => setObligatory(e.target.checked)} />
        <Typography>Si</Typography>
      </Box>

    </>
  );
};

export default MultiCheckbox;
