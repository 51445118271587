import { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetElemDataById from "../../../hooks/useGetElemDataById";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
const Update = ({ handleOpen, open, id }) => {
  const table = "Chats"
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const prev = useGetElemDataById(table, id,  "id");

  const [selecteduser_id, setSelecteduser_id] = useState();
  const [selectedchat_type_id, setSelectedchat_type_id] = useState();
  const usersTemp = useGetUnlimitedListOf("Users", "", "list");
  const usersData = usersTemp.listElements;

  const chat_typesTemp = useGetUnlimitedListOf("Chat_Types", "", "list");
  const chat_typesData = chat_typesTemp.listElements;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

useEffect(() => {
    if (prev) {
      if(prev.user_id) {
        setSelecteduser_id(prev.user_id);
        setValue("user_id", prev.user_id);
      }
      if(prev.chat_type_id) {
        setSelectedchat_type_id(prev.chat_type_id);
        setValue("chat_type_id", prev.chat_type_id);
      }
    }
  }, [prev]);

  const update = async (data) => {
if (!data.user_id?.trim() || !data.chat_type_id?.trim() || data.expire === null) {
    window.alert("user_id, chat_type_id, expire non possono essere vuoti!");
    return;
  }

data.expire = dayjs(data.expire).format("YYYY-MM-DD")

    const nullColumns = Object.keys(data)
    .filter(key => data[key] === null || data[key] === "")
    .join("|");

    const deletedData = data;
    delete deletedData.id;
    try {
      const updatedData = {
				id: prev.id,
        new_values: deletedData,
        str_list_null: nullColumns,
      };

      console.log(updatedData);

      const response = await axiosPrivate.put(`/${table}/update`, updatedData);
      if (response) {
        window.alert("Modifica avvenuta con successo!");
        navigate(0);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/unauthorized", {
          state: { from: location },
          replace: true,
        });
      } else {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };

  if (prev.length !== 0) {
    return (
      <>

        <Modal open={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ mb: 2 }}
            >
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Aggiorna l'elemento con id {id} alla tabella {table}
              </Typography>
              <Button
                color="error"
                onClick={() => handleOpen(false, "mod")}
                sx={{
                  maxWidth: "30px",
                  maxHeight: "30px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            {/* Autocomplete per il campo user_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={usersData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selecteduser_id
                  ? usersData.find((user) => user.id === selecteduser_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelecteduser_id(newValue.id);
                  setValue("user_id", newValue.id);
                } else {
                  setSelecteduser_id(null);
                  setValue("user_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select user_id"
                  placeholder="user_id"
                  error={!!errors.user_id}
                  helperText={
                    errors.user_id ? "Il campo user_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
            {/* Autocomplete per il campo chat_type_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={chat_typesData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedchat_type_id
                  ? chat_typesData.find((bot) => bot.id === selectedchat_type_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedchat_type_id(newValue.id);
                  setValue("chat_type_id", newValue.id);
                } else {
                  setSelectedchat_type_id(null);
                  setValue("chat_type_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select chat_type_id"
                  placeholder="chat_type_id"
                  error={!!errors.chat_type_id}
                  helperText={
                    errors.chat_type_id ? "Il campo chat_type_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
						{Object.entries(prev)
              .filter(([key]) => true && key !== "id" && key !== "user_id" && key !== "chat_type_id" && key !== "expire" && key !== "active")
              .map(([key, value], index) => (
                <TextField
                  key={index}
                  placeholder={key}
                  defaultValue={value}
                  sx={{ width: "50%", m: 1 }}
                  {...register(key)}
                />
              ))}
{/* switch per campo active */}
<Controller
      name="active"
      control={control}
      defaultValue={prev.active}
      render={({ field }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography sx={{ mr: 2 }}>active:</Typography>
          <Switch color="primary" {...field} checked={field.value} />
        </Box>
      )}
    />

{/* datepicker per campo expire */}
<LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="expire"
              control={control}
              defaultValue={dayjs(prev.expire)}
              render={({ field }) => (
                <DatePicker
					sx={{m:1}}
                  label="expire"
                  inputFormat="DD/MM/YYYY"
                  value={field.value}
                  onChange={(date) => field.onChange(date)}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "50%", m: 1 }} />
                  )}
                />
              )}
            />
          </LocalizationProvider>

            <Button onClick={handleSubmit(update)}>SALVA MODIFICHE</Button>
          </Box>
        </Modal>
      </>
    );
  }
};

export default Update;