import { Button } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const AlphegaPromotionsBtn = () => {
  const navigate = useNavigate();

  return (
    <Button
      fullWidth={isMobile || (window.innerWidth <= 785 && true)}
      onClick={() => navigate("/alphega_centrale/alphega_promotions")}
    >
      COMUNICAZIONI
    </Button>
  );
};

export default AlphegaPromotionsBtn;
