import React, { useState } from "react";

import PropTypes from "prop-types";

import Container from "@mui/material/Container";
import {
  Box,
  Button,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LogoPublic from "../../components/layout/LogoPublic";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PharmaciesNewAccessLinkRequest = () => {
  const axiosPrivate = useAxiosPrivate();
  const [value, setValue] = useState(0);
  const [input, setInput] = useState("");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setInput("");
  };

  const handleSubmit = async (inputType) => {
    console.log(typeof value);
    try {
      let userData = {};
      if (input.length !== 0) {
        if (value === 0) {
          //EMAIL
          const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
          if (!emailRegex.test(input))
            return window.alert("Devi inserire un indirizzo mail valido!");

          userData.type = "email";
        } else if (value === 1) {
          //TELEFONO
          const phoneRegex = /^\d{7,10}$/;
          if (!phoneRegex.test(input))
            return window.alert("Devi inserire un numero di cellulare valido!");

          userData.type = "telephone";
        }

        userData.value = value === 1 ? "39" + input : input; // DA CAMBIARE, PREFISSO DEVE ESSERCI GIA'

        const response = await axiosPrivate.post(
          "/Chat_Messages/renewed_access_sender",
          {
            user_data: userData,
          }
        );

        if (response) {
          window.alert("Link di accesso inviato. Controlla la tua chat WhatsApp con Alphega!");
          window.location.replace("/");
        }
      }
    } catch (err) {
      if (!err?.response) {
        window.alert("Errore. Riprova.");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert(`Errore: ${err.response.data.detail}`);
      }
    }
  };

  return (
    <>
      <Container component={Paper} maxWidth="md" sx={{ py: 5 }}>
        <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
          <Box sx={{ px: "25%", mb: 2 }}>
            <LogoPublic />
          </Box>
          <Typography component="h1" variant="h5" sx={{ m: 0 }}>
            Il tuo link di accesso ad Alphega Comunicazioni è scaduto!
          </Typography>
          <br />
          Puoi richiederne uno nuovo inserendo la tua email o il tuo numero di
          cellulare registrati su Alphega.
          <br />
          Riceverai un messaggio su whatsapp con il nuovo link
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            variant="fullWidth"
          >
            <Tab
              label={
                <Typography variant="h6" fontSize={15}>
                  Richiedi con E-Mail
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Typography variant="h6" fontSize={15}>
                  Richiedi con Cellulare
                </Typography>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <Box sx={{ mt: -2 }}>
          <CustomTabPanel value={value} index={0}>
            <TextField
              fullWidth
              label="Inserisci il tuo indirizzo email"
              sx={{ my: 2 }}
              onChange={(e) => setInput(e.target.value)}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <TextField
              fullWidth
              label="Inserisci il tuo numero di cellulare"
              type="text"
              slotProps={{
                htmlInput: {
                  inputMode: "numeric",
                  pattern: "/^-?d+(?:.d+)?$/g",
                },
              }}
              sx={{ my: 2 }}
              onChange={(e) => setInput(e.target.value)}
            />
          </CustomTabPanel>
        </Box>
        <Button
          fullWidth
          variant="contained"
          disabled={input.length === 0 ? true : false}
          onClick={handleSubmit}
        >
          Invio
        </Button>
      </Container>
    </>
  );
};

export default PharmaciesNewAccessLinkRequest;
