import React, { useEffect, useState } from "react";

import { Box, Switch, TextField, Typography } from "@mui/material";

const TextDispaly = ({ componentId, saveComponent }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSave = () => {
    saveComponent(componentId, title, description, [], false);
  };

  useEffect(() => {
    handleSave();
  }, [title, description]);
  return (
    <>
      <Typography variant="h5">Testo</Typography>
      Inserisci un testo da mostrare nella comunicazione
      <TextField
        fullWidth
        label="Titolo paragrafo"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        style={{ marginTop: 8 }}
      />
      <TextField
        fullWidth
        label="Testo paragrafo"
        multiline
        rows={2}
        value={description}
        onChange={(e) => {setDescription(e.target.value)}}
        style={{ marginTop: 16 }}
      />
    </>
  );
};

export default TextDispaly;
