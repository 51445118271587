import { Box, TextField, Typography } from "@mui/material";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useEffect, useState } from "react";

const DateTimeSelect = ({ componentId, saveComponent }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState();
  const [options, setOptions] = useState([]);

  const handleSave = () => {
    saveComponent(componentId, title, description, options, false);
  };

  useEffect(() => {
    handleSave();
  }, [title, description, options]);

  return (
    <>
      <Typography variant="h5">Data e ora</Typography>
      Consente di selezionare una data e un'ora
      <Box>
        <TextField
          fullWidth
          label="Titolo"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ marginTop: 8 }}
        />
        <TextField
          fullWidth
          label="Descrizione"
          multiline
          rows={2}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ mt: 2, mb: 3 }}
        />
      </Box>
      <Box
        sx={{
          mx: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDateTimePicker
          
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CalendarMonthIcon /> Data
              </Box>
            }
            ampm={false}
            value={date}
            format="DD/MM/YYYY HH:mm"
            onChange={(date) => {
              setOptions([date])
            }}
            sx={{ width: "45%" }}
          />
        </LocalizationProvider>
      </Box>
    </>
  );
};

export default DateTimeSelect;
