import React from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../../../components/navbar/NavBar";
import PromotionsDetail from "../../../../components/promotions/PromotionsDetail";

const AlphegaPromotionsDetail = () => {
  const { idPromotion } = useParams();
  return (
    <>
      <NavBar type="4719" />
      <PromotionsDetail promotionId={idPromotion} user="Alphega_Centrale" />
    </>
  );
};

export default AlphegaPromotionsDetail;
