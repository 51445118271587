import React from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function createData(
  id,
  business_name,
  email,
  customer_internal_code
  //colonne tabella
) {
  return {
    id,
    business_name,
    email,
    customer_internal_code,
    //colonne tabella
  };
}

const UsersList = (users, userType, frontendParams) => {
  const navigate = useNavigate();
  const renderTableHeader = (header, title) => {
    if (
      frontendParams.order_key === header &&
      frontendParams.order_desc === "true"
    )
      return (
        <React.Fragment>
          <ArrowDropDownIcon />
          {title}
        </React.Fragment>
      );
    else if (
      frontendParams.order_key === header &&
      frontendParams.order_desc === "false"
    )
      return (
        <React.Fragment>
          <ArrowDropUpIcon />
          {title}
        </React.Fragment>
      );
  };

  const rows = [];

  for (let i = 0; i < users?.length; i++) {
    rows.push(
      createData(
        users[i]["users.id"],
        users[i]["user_anagraphics.business_name"],
        users[i]["users.email"],
        users[i]["user_anagraphics.customer_internal_code"]
      )
    );
  }

  const columns = [
    {
      field: "business_name",
      headerName: "Ragione sociale",
      flex: 1,
      // minWidth: 700,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            size="large"
            sx={{ textAlign: "left", justifyContent: "flex-start" }}
            onClick={() => navigate(`${params.row.id}`)}
          >
            <b>{params.value}</b>
          </Button>
        );
      },
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            size="large"
            sx={{ textAlign: "left", justifyContent: "flex-start" }}
            onClick={() => navigate(`${params.row.id}`)}
          >
            {params.value}
          </Button>
        );
      },
    },
  ];

  if (userType === "alphega_centrale") {
    columns.unshift({
      field: "customer_internal_code",
      headerName: "Codice centralizzato",
      flex: 1,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            size="large"
            sx={{ textAlign: "left", justifyContent: "flex-start" }}
            onClick={() => navigate(`${params.row.id}`)}
          >
            {params.value}
          </Button>
        );
      },
    });
  }

  return { rows: rows, columns: columns };
};

export default UsersList;
