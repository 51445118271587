import React, { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { Copyright } from "@mui/icons-material";
import { Box, Container, CssBaseline, Divider, Grid, Typography } from "@mui/material";

import { axiosAuth } from "../../api/axios";
import useAuth from "../../hooks/auth/useAuth";
import LogoPublic from "../../components/layout/LogoPublic";
import LoadingSpinner from "../../components/layout/LoadingSpinner";

const PharmaciesAccessToPromotionRedirect = () => {
  const { setAuth } = useAuth();
  const { key } = useParams();
  const navigate = useNavigate();

  const authenticate = async () => {
    try {
      const response = await axiosAuth.post(
        "/login_from_token_and_promotion",
        { key: key },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      if (response) {
        setAuth(response?.data?.result.accessToken);
        if (response?.data?.result.role === "8632") navigate(`/pharmacies_promotions/${response?.data?.result.promotion_id}`);
        else navigate("*");
      }
    } catch (err) {
      if (!err?.response) {
        window.alert("Errore. Autenticazione fallita. Url non valido");
      }
    }
  };

  useEffect(() => {
    authenticate();
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LogoPublic />
        <Divider sx={{ my: 10 }} />
        <Typography component="h1" variant="h5">
          Accesso in corso
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
          <Grid>
            <LoadingSpinner />
          </Grid>
        </Box>
        <Copyright sx={{ bottom: 10, position: "absolute" }} />
      </Box>
    </Container>
  );
};

export default PharmaciesAccessToPromotionRedirect;
