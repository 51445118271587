import axios from "axios";
// const BASE_URL = 'https://192.168.100.58:8888/';
const BASE_URL = 'https://alphega-comunicazioni.it/api/';

export default axios.create({
    baseURL: BASE_URL,
});

export const axiosAuth = axios.create({
    baseURL: BASE_URL+'auth'
});

export const axiosPrivate =  axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    secure: true
});