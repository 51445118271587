import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import useAxiosPrivate from "../useAxiosPrivate";

const useGetUsersForAssignment = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [listElements, setListElements] = useState([]);
  const [totalElements, setTotalElements] = useState();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const list = async () => {
      try {
        const response = await axiosPrivate.get(
          "/Users/list?page=0&limit=999999999&complete=true"
        );
        if (isMounted) {
          setListElements(response.data?.result.list_element); // && => se isMounted == true
          setTotalElements(response.data?.result.total_element);
        }
      } catch (err) {
        if (err.response?.status === 401) {
          navigate("/unauthorized", {
            state: { from: location },
            replace: true,
          });
        } else {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    list();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, location, navigate, totalElements]);

  return {
    listElements: listElements,
    totalElements: totalElements,
  };
};

export default useGetUsersForAssignment;
