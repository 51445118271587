import React from "react";
import NavBar from "../components/navbar/NavBar";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Tables = () => {
  const navigate = useNavigate();

  const chatsCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Chats</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/bot/chats")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const chat_messagesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Chat_Messages</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/bot/chat_messages")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const chat_typesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Chat_Types</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/bot/chat_types")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const cluster_centroidsCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Cluster_Centroids</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/cluster/cluster_centroids")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const cluster_elementsCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Cluster_Elements</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/cluster/cluster_elements")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const cluster_typesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Cluster_Types</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/cluster/cluster_types")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const filesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Files</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/file/files")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const file_typesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">File_Types</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/file/file_types")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const assignment_statesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Assignment_States</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/promotion/assignment_states")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const input_typesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Input_Types</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/promotion/input_types")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const promotionsCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Promotions</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/promotion/promotions")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const promotion_answersCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Promotion_Answers</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/promotion/promotion_answers")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const promotion_assignmentsCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Promotion_Assignments</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/promotion/promotion_assignments")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const promotion_attachmentsCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Promotion_Attachments</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/promotion/promotion_attachments")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const promotion_componentsCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Promotion_Components</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/promotion/promotion_components")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const promotion_typologiesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Promotion_Typologies</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/promotion/promotion_typologies")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const rolesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Roles</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/user/roles")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const usersCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Users</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => navigate("/tables/user/users")}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );
  return (
    <>
      <NavBar />
      <Typography variant="h3" sx={{ m: 5 }}>
        Seleziona una tabella su cui eseguire le operazioni
      </Typography>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        sx={{ width: "95%", mx: 10 }} 
        justifyContent={"space-evenly"}
      >
        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {chatsCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {chat_messagesCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {chat_typesCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {cluster_centroidsCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {cluster_elementsCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {cluster_typesCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {filesCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {file_typesCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {assignment_statesCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {input_typesCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {promotionsCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {promotion_answersCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {promotion_assignmentsCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {promotion_attachmentsCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {promotion_componentsCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {promotion_typologiesCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {rolesCard}
        </Card>

        <Card sx={{ width: "30%", mb: 2 }} variant="outlined">
          {usersCard}
        </Card>
 {/* ancora per cards */}
      </Box>
    </>
  );
};

export default Tables;