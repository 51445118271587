import React from "react";
import useGetListOf from "../../../hooks/useGetListOf";
import FilesDataDisplay from "../../files/FilesDataDisplay";

const UsersDetailFiles = ({ userId }) => {

  const userPhoto = useGetListOf(
    "Files",
    `where_key=user_id&where_value=${userId}`,
    "list"
  );
  const userPromotions = useGetListOf(
    "Promotions",
    `where_key=promotion_assignments.user_id&where_value=${userId}`,
    "list"
  );

  return (
    <FilesDataDisplay photoData={userPhoto} promotionsData={userPromotions} />
  );
};

export default UsersDetailFiles;
