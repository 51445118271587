import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

const PricingTable = ({
  description,
  componentId,
  options,
  handleAnswers,
  answer,
  disabled,
  obligatory,
  setHasError,
}) => {
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (Array.isArray(answer)) {
      setAnswers(answer);
    } else {
      setAnswers(new Array(options.length).fill("")); // inizializza array vuoto con dimensioni corrette
    }
  }, [answer, options.length]);

  const handleAddAnswers = (value, index) => {
    const newAnswers = [...answers];

    // Aggiorna il valore alla posizione corretta
    newAnswers[index] = value;

    setAnswers(newAnswers); // Aggiorna lo stato

    // Verifica se tutte le risposte sono state date (non ci sono valori vuoti)
    const allAnswered = newAnswers.every((answer) => answer.trim() !== "");

    // Crea l'oggetto answerData da passare
    const answerData = {
      componentId: componentId,
      answer: newAnswers,
      obligatory: obligatory,
    };

    // Chiama handleAnswers anche se non tutte le risposte sono presenti
    handleAnswers(answerData);

    // Se non tutte le risposte sono presenti, segnala errore (facoltativo)
    setHasError && setHasError(!allAnswered);
  };

  return (
    <TableContainer component={Paper} sx={{ mb: 3 }}>
      <Table sx={{ border: "2px solid #009534", scale: isMobile && "60%", my: isMobile && -8, mx: isMobile && -19 }} aria-label="simple table">
        <TableHead
          sx={{
            background: "#009534",
            "& .MuiTableCell-head": {
              color: "white",
              fontWeight: 700,
            },
          }}
        >
          <TableRow>
            <TableCell>MINSAN PRODOTTO</TableCell>
            <TableCell align="left" sx={{ width: "14%" }}>
              PRODOTTO
            </TableCell>
            <TableCell align="left" sx={{ width: "14%" }}>
              P.P. CONSIGLIATO PRODOTTO
            </TableCell>
            <TableCell align="left" sx={{ width: "14%" }}>
              COMPOS. PEZZI
            </TableCell>
            <TableCell align="left" sx={{ width: "13%" }}>
              SCONTO NETTO
            </TableCell>
            <TableCell align="left" sx={{ width: "16%" }}>
              P. CESSIONE NETTO PRODOTTO
            </TableCell>
            <TableCell align="left" sx={{ width: "14%" }}>
              P. CESSIONE NETTO ESPO
            </TableCell>
            {obligatory && (
              <TableCell align="left" sx={{ width: "14%" }}>
                ESPO ORDINATI
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {options.map((option, index) => (
            <TableRow
              key={option.minsan}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {option.label.minsan}
              </TableCell>
              <TableCell align="left">{option.label.prodotto}</TableCell>
              <TableCell align="left">{option.label.prConsigliato} €</TableCell>
              <TableCell align="left">{option.label.compos}</TableCell>
              <TableCell align="left">{option.label.sconto} %</TableCell>
              <TableCell align="left">{option.label.netProd} €</TableCell>
              <TableCell align="left">{option.label.netEspo} €</TableCell>
              {obligatory && (
                <TableCell align="left">
                  <TextField
                    onChange={(e) => handleAddAnswers(e.target.value, index)}
                    value={answers[index] || ""}
                    disabled={disabled}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PricingTable;
