import React from "react";

import LogoutBtn from "./singleButtons/LogoutBtn";
import AlphegaHomeBtn from "./singleButtons/AlphegaHomeBtn";
import AlphegaUsersBtn from "./singleButtons/AlphegaUsersBtn";
import AlphegaPromotionsBtn from "./singleButtons/AlphegaPromotionsBtn";
import ProfileBtn from "./singleButtons/ProfileBtn";
import { isMobile } from "react-device-detect";
import AlphegaFilesBtn from "./singleButtons/AlphegaFilesBtn";

const AlphegaNavbar = () => {
  return (
    <>
      <AlphegaHomeBtn />
      <AlphegaUsersBtn />
      <AlphegaPromotionsBtn />
      <ProfileBtn to="/alphega_centrale/profile"/>
      <AlphegaFilesBtn/>
      {isMobile && <LogoutBtn />}
    </>
  );
};

export default AlphegaNavbar;
