import { TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";


const LinkComponent = ({ componentId, saveComponent }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSave = () => {
    saveComponent(componentId, title, description, [], false);
  };

  useEffect(() => {
    handleSave();
  }, [title, description]);

  return (
    <>
      <Typography variant="h5"> Link</Typography>
      Crea un elemento che contiene un link a scelta
      <TextField
        fullWidth
        label="Titolo"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        style={{ marginTop: 8, marginBottom: 20 }}
      />
      <TextField
        fullWidth
        label="Link"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{ marginTop: 8, marginBottom: 20 }}
      />
    </>
  );
};

export default LinkComponent;
