import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../useAxiosPrivate";

const useGetPromotionComponents = (id) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [infos, setInfos] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const list = async () => {
      try {
        const response = await axiosPrivate.get(`/Promotion_Attachments/list?where_key=promotion_id&where_value=${id}`);
        isMounted && setInfos(response.data?.result.list_element); // && => se isMounted == true
      } catch (err) {
        if (err.response?.status === 401) {
          navigate("/unauthorized", {
            state: { from: location },
            replace: true,
          });
        }
      }
    };

    list();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, id, location, navigate]);

  return infos;
};

export default useGetPromotionComponents;