import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../useAxiosPrivate";

const useGetCompleteDetails = (promotionId, userId) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [infos, setInfos] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const list = async () => {
      try {
        let query_params = `?id=${promotionId}`;
        if (userId) query_params += `&user_id=${userId}`;
        const response = await axiosPrivate.get(
          `/Promotions/get_complete_by_id` + query_params
        );

        isMounted && setInfos(response.data?.result); // && => se isMounted == true
      } catch (err) {
        if (err.response?.status === 401) {
          navigate("/unauthorized", {
            state: { from: location },
            replace: true,
          });
        } else if (err.response?.status === 404) {
          navigate("/*", {
            state: { from: location },
            replace: true,
          });
        } else {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    list();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, promotionId, location, navigate, userId]);

  return infos;
};

export default useGetCompleteDetails;
