import { Box } from "@mui/material";

import ProfileData from "../../../components/users/ProfileData";
import ProfileDataEdit from "../../../components/users/ProfileDataEdit";
import ProfileDataChangePsw from "../../../components/users/ProfileDataChangePsw";
// import { CreditsProvider } from "../../../context/CreditsProvider";

const Profile = ({ action }) => {
  return (
    // <CreditsProvider>
      <Box sx={{ display: "flex" }}>
        {action.value === "no-edit" ? (
          <ProfileData />
        ) : action.value === "edit" ? (
          <ProfileDataEdit />
        ) : action.value === "edit-psw" ? (
          <ProfileDataChangePsw />
        ) : (
          console.log("error: no profile page type found")
        )}
      </Box>
    // </CreditsProvider>
  );
};

export default Profile;
