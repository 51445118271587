import useAuth from "./useAuth";
import { axiosAuth } from "../../api/axios";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axiosAuth.get("/refresh", {
      withCredentials: true,
    });
    setAuth((prev) => {
      // console.log(JSON.stringify(prev));
      // console.log(response.data.result);
      return {
        ...prev,
        // roles: response.data.roles,
        accessToken: response.data.result,
      };
    });
    return response.data.result;
  };
  return refresh;
};

export default useRefreshToken;
