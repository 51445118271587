import { Box, Button, Typography } from "@mui/material";
import React from "react";

const Link = ({ description }) => {
  return (
    <Box
      sx={{
        mt: 1,
        mb: 6,
        mx: "5%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography sx={{mb: 3}} variant="body1">{description}</Typography>
      <Button variant="contained" onClick={() => window.open(description)}>
        APRI LINK
      </Button>
    </Box>
  );
};

export default Link;
