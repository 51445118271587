import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Modal,
  Typography,
} from "@mui/material";

const PromotionAttachmentModal = ({ open, onClose, file, onFile }) => {
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box display={"flex"} justifyContent={"Space-between"}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Allegati
          </Typography>
          <Button
            color="error"
            onClick={handleClose}
            sx={{
              maxWidth: "30px",
              maxHeight: "30px",
              minWidth: "30px",
              minHeight: "30px",
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
        <List>
          <Divider sx={{ bgcolor: "#009534", m: 1 }} />
          {file.map((elem, index) => (
            <Box key={index}>
              <ListItem>
                <Button
                  fullWidth
                  // variant="contained"
                  onClick={() => onFile(elem.id, "", elem.title)}
                  endIcon={<DownloadIcon />}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    whiteSpace: "normal",
                    wordBreak: "break-all",
                    textAlign: "center",
                  }}
                >
                  {elem.title}
                </Button>
              </ListItem>
              <Divider sx={{ bgcolor: "#009534", m: 1 }} />
            </Box>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export default PromotionAttachmentModal;
