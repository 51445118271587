import React from "react";
import NavBar from "../../../../components/navbar/NavBar";
import { Box, Typography } from "@mui/material";
import ElementsList from "../../../../components/ElementsList";


const PharmaciesPromotions = () => {
  return (
    <>
      <NavBar type="8632" />
      <Box sx={{ px: "10%", mb: 1 }}>
        <Typography component="h1" variant="h5">
          Le mie comunicazioni
        </Typography>
        Qui puoi consultare tutte le comunicazioni di Alphega per la tua farmacia
      </Box>
      <ElementsList type="Promotion_Assignments" user="Farmacia" variantSearchKeys="complete=true" />
    </>
  );
};

export default PharmaciesPromotions;
