import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Nunito, sans-serif",
    h1: {
      fontFamily: "Quicksand, sans-serif",
      color: "#009534",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: "Quicksand, sans-serif",
      color: "#009534",
      fontWeight: "bold",
    },
    h3: {
      fontFamily: "Quicksand, sans-serif",
      color: "#009534",
      fontWeight: "bold",
    },
    h4: {
      fontFamily: "Quicksand, sans-serif",
      color: "#009534",
      fontWeight: "bold",
    },
    h5: {
      fontFamily: "Quicksand, sans-serif",
      color: "#009534",
      fontWeight: "bold",
    },
    h6: {
      fontFamily: "Quicksand, sans-serif",
      color: "#009534",
      fontWeight: "bold",
    },
  },
  palette: {
    primary: {
      main: "#009534",
      light: "#13a538",
    },
    secondary: {
      main: "#e01a1c",
    },
    error: {
      main: "#e01a1c",
    },
    background: {
      default: '#f4f6f8',  // Colore di sfondo predefinito
      paper: '#ffffff',     // Colore di sfondo dei componenti Paper
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Quicksand, sans-serif",
          fontWeight: "bold",
        },
      },
    },
    // MuiTypography: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: "Quicksand, sans-serif",
    //       fontWeight: "bold",
    //     },
    //   },
    // },
  },
});
console.log("Tema applicato:", theme);

export default theme;
