import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import useGetListOf from "../../hooks/useGetListOf";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import PromotionsTypeListFilter from "../promotions/PromotionsTypeListFilter";
import PromotionsStatusListFilter from "../promotions/PromotionsStatusListFilter";

const ImagesUpload = () => {
  // const promotionTypes = useGetListOf(
  //   "Promotion_Typologies",
  //   "limit=1000&order_key=order",
  //   "list"
  // );
  const alreadyUploadedFilesCount = useGetListOf(
    "Files",
    "",
    "list"
  ).totalElements;
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [alignment, setAlignment] = useState("comunication");
  const [file, setFile] = useState(null);
  const [fileKey, setFileKey] = useState(Date.now());
  const [selectedType, setSelectedType] = useState("");
  const [acceptedPromotions, setAcceptedPromotions] = useState(null);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [dataForOther, setDataForOther] = useState({ title: "Altro" });

  const handlePromotionTypeSelection = (id) => {
    setSelectedType(id);
  };

  const handleUploadTypeChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    setSelectedType("");
    setSelectedPromotion(null);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileKey(Date.now());
  };

  const handleFileUpload = async () => {
    try {
      const formData = new FormData();

      formData.append("file", file);

      formData.append(
        "title",
        alignment === "comunication"
          ? selectedPromotion.promotion_type
          : alignment === "other" && dataForOther.title
      );

      formData.append(
        "description",
        alignment === "comunication"
          ? selectedPromotion.promotion_title
          : alignment === "other" && dataForOther.description
      );

      if (selectedPromotion) {
        formData.append(
          "promotion_assignment_id",
          selectedPromotion.promotion_assignment_id
        );
      }

      const upload = await axiosPrivate.post(
        "/Files/create_with_file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (upload) {
        window.alert("File caricato con successo!");
        navigate(0);
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  const obtainPromotions = async () => {
    try {
      let param = "";
      if (selectedType) param = `?promotion_typology_id=${selectedType}`;
      const response = await axiosPrivate.get(
        `/Promotions/list_assigned_by_typology${param}`
      );
      if (response) {
        setAcceptedPromotions(response.data.result.list_element);
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
    }
  };

  useEffect(() => {
    obtainPromotions();
  }, [selectedType]);

  return (
    <Container component={Paper} sx={{ pt: 2 }}>
      <Box
        sx={{
          background: alreadyUploadedFilesCount >= 20 ? "#e01a1c" : "#009534",
          color: "white",
          borderRadius: 2,
          py: 1,
          mb: 1,
          boxShadow: 2,
        }}
      >
        <Typography>
          Numero di foto caricabili rimanenti:
          <br />
          <b>{20 - alreadyUploadedFilesCount} *</b>
        </Typography>
      </Box>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleUploadTypeChange}
        aria-label="Platform"
        sx={{ m: 1 }}
      >
        <ToggleButton value="comunication" sx={{ width: 150 }}>
          File per comunicazione
        </ToggleButton>
        <ToggleButton value="other" sx={{ width: 150 }}>
          Altro file
        </ToggleButton>
      </ToggleButtonGroup>

      <Typography sx={{ m: 1 }}>
        {alignment === "comunication"
          ? "Seleziona la comunicazione da associare a cui associare il tuo file"
          : alignment === "other" && "Inserisci un titolo per il file"}
      </Typography>
      {alignment === "comunication" ? (
        <>
          <Box sx={{ mb: 2, width: "80%", mx: "auto" }}>
            <PromotionsTypeListFilter
              onTypeSet={handlePromotionTypeSelection}
              searchParams={""}
              // user={list.user}
            />
          </Box>

          {/* AUTOCOMPLETE */}
          {/* {selectedType && selectedType !== null && ( */}
          <Box display={"flex"} justifyContent={"center"} sx={{ mb: 2 }}>
            <Autocomplete
              onChange={(event, newValue) => {
                setSelectedPromotion(newValue);
              }}
              value={selectedPromotion}
              disablePortal
              options={acceptedPromotions}
              getOptionLabel={(option) => option.promotion_title}
              sx={{ width: "80%" }}
              renderInput={(params) => (
                <TextField {...params} label="Scegli comunicazione" />
              )}
              disabled={alreadyUploadedFilesCount >= 20}
            />
          </Box>
          {/* )} */}
        </>
      ) : (
        alignment === "other" && (
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Inserisci un titolo"
              variant="outlined"
              sx={{ width: "80%" }}
              onChange={(e) =>
                setDataForOther((prevState) => ({
                  ...prevState,
                  description: e.target.value,
                }))
              }
              disabled={alreadyUploadedFilesCount >= 20}
            />
          </Box>
        )
      )}
      <Box>
        <Input
          key={fileKey}
          id="file-input"
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          inputProps={{
            accept: "image/png, image/jpeg",
          }}
          disabled={alreadyUploadedFilesCount >= 20}
        />
        {file ? (
          <>
            File selezionato:{" "}
            <Typography variant="h6">
              {file.name}
              <IconButton color="error" onClick={handleFileRemove}>
                <DeleteIcon />
              </IconButton>
            </Typography>
          </>
        ) : (
          <label htmlFor="file-input">
            <Button
              variant="outlined"
              color="success"
              component="span"
              sx={{ mb: 3 }}
              disabled={alreadyUploadedFilesCount >= 20}
            >
              <AddIcon sx={{ fontSize: 50 }} />
              Carica foto
            </Button>
          </label>
        )}
      </Box>
      <Button
        fullWidth
        onClick={handleFileUpload}
        variant="contained"
        sx={{ my: 2 }}
        endIcon={alreadyUploadedFilesCount >= 20 ? <CloseIcon /> : <SendIcon />}
        disabled={alreadyUploadedFilesCount >= 20}
      >
        {alreadyUploadedFilesCount >= 20
          ? "LIMITE FOTO RAGGIUNTO"
          : "CARICA FOTO"}
      </Button>
      <Box sx={{ pb: 2 }}>
        <Typography
          lineHeight={"15px"}
          fontSize={"10pt"}
          color={"grey"}
          textAlign={"left"}
        >
          * ciascuna immagine caricata resta sul sistema per 20 giorni,
          dopodichè viene cancellata. Puoi avere un massimo di 20 immagini
          caricate sul sistema. Lo spazio si libera a allo scadere delle foto
          già caricate.
        </Typography>
      </Box>
    </Container>
  );
};

export default ImagesUpload;
