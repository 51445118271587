import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const PricingTable = ({ componentId, saveComponent }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([]);

  const [entry1, setEntry1] = useState("");
  const [entry2, setEntry2] = useState("");
  const [entry3, setEntry3] = useState("");
  const [entry4, setEntry4] = useState("");
  const [entry5, setEntry5] = useState("");
  const [entry6, setEntry6] = useState("");
  const [entry7, setEntry7] = useState("");
  const [entry8, setEntry8] = useState("");

  const handleSave = () => {
    saveComponent(componentId, title, description, options, false);
  };

  useEffect(() => {
    handleSave();
  }, [title, description, options]);

  function createData(
    minsan,
    prodotto,
    prConsigliato,
    compos,
    sconto,
    netProd,
    netEspo,
    quantity
  ) {
    if (
      entry1 !== "" ||
      entry2 !== "" ||
      entry3 !== "" ||
      entry4 !== "" ||
      entry5 !== "" ||
      entry6 !== "" ||
      entry7 !== "" ||
      entry8 !== ""
    ) {
      const row = {
        minsan,
        prodotto,
        prConsigliato,
        compos,
        sconto,
        netProd,
        netEspo,
        quantity,
      };
      setOptions((prevOptions) => [...prevOptions, row]);
      setEntry1("");
      setEntry2("");
      setEntry3("");
      setEntry4("");
      setEntry5("");
      setEntry6("");
      setEntry7("");
      setEntry8("");
    }
  }

  const removeRow = (index) => {
    setOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
  };

  return (
    <>
      <Typography variant="h5">Tabella</Typography>
      Consente la creazione di una tabella con un numero arbitrario di righe
      <Box>
        <TextField
          fullWidth
          label="Titolo *"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ marginTop: 8 }}
        />
        <TextField
          fullWidth
          label="Descrizione"
          multiline
          rows={2}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ mt: 2, mb: 3 }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, border: "2px solid #009534" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              background: "#009534",
              "& .MuiTableCell-head": {
                color: "white",
                fontWeight: 700,
              },
            }}
          >
            <TableRow>
              <TableCell sx={{ width: 100, fontSize: "10px" }}>
                MINSAN PRODOTTO
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: 100, fontSize: "10px", p: 0 }}
              >
                PRODOTTO
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: 100, fontSize: "10px", p: 0 }}
              >
                P.P. CONSIGLIATO PRODOTTO
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: 100, fontSize: "10px", p: 0 }}
              >
                COMPOS. PEZZI
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: 100, fontSize: "10px", p: 0 }}
              >
                SCONTO NETTO
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: 100, fontSize: "10px", p: 0 }}
              >
                P. CESSIONE NETTO PRODOTTO
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: 100, fontSize: "10px", p: 0 }}
              >
                P. CESSIONE NETTO ESPO
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: 100, fontSize: "10px", p: 0 }}
              >
                QUANTITA' MINIMA
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: 100, fontSize: "10px", p: 0 }}
              >
                AZIONI
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {options.map((option, index) => (
              <TableRow
                key={option.minsan}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {option.minsan}
                </TableCell>
                <TableCell sx={{ p: 0 }} align="left">
                  {option.prodotto}
                </TableCell>
                <TableCell sx={{ p: 0 }} align="left">
                  {option.prConsigliato}
                </TableCell>
                <TableCell sx={{ p: 0 }} align="left">
                  {option.compos}
                </TableCell>
                <TableCell sx={{ p: 0 }} align="left">
                  {option.sconto}
                </TableCell>
                <TableCell sx={{ p: 0 }} align="left">
                  {option.netProd}
                </TableCell>
                <TableCell sx={{ p: 0 }} align="left">
                  {option.netEspo}
                </TableCell>
                <TableCell sx={{ p: 0 }} align="left">
                  {option.quantity}
                </TableCell>
                <TableCell sx={{ p: 0 }} align="left">
                  <Button onClick={() => removeRow(index)}>
                    <DeleteIcon color="error" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {/* RIGA PER INSERIMENTO */}
            <TableRow>
              <TableCell sx={{ p: 0.1 }}>
                <TextField
                  size="small"
                  value={entry1}
                  onChange={(e) => setEntry1(e.target.value)}
                  variant="outlined"
                  label="minsan"
                  fullWidth
                />
              </TableCell>
              <TableCell sx={{ p: 0.1 }}>
                <TextField
                  size="small"
                  value={entry2}
                  onChange={(e) => setEntry2(e.target.value)}
                  variant="outlined"
                  label="prodotto"
                  fullWidth
                />
              </TableCell>
              <TableCell sx={{ p: 0.1 }}>
                <TextField
                  size="small"
                  value={entry3}
                  onChange={(e) => setEntry3(e.target.value)}
                  variant="outlined"
                  label="p.p. consigliato prodotto"
                  fullWidth
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    },
                  }}
                />
              </TableCell>
              <TableCell sx={{ p: 0.1 }}>
                <TextField
                  size="small"
                  value={entry4}
                  onChange={(e) => setEntry4(e.target.value)}
                  variant="outlined"
                  label="compos. pezzi"
                  fullWidth
                />
              </TableCell>
              <TableCell sx={{ p: 0.1 }}>
                <TextField
                  size="small"
                  value={entry5}
                  onChange={(e) => setEntry5(e.target.value)}
                  variant="outlined"
                  label="sconto netto"
                  fullWidth
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    },
                  }}
                />
              </TableCell>
              <TableCell sx={{ p: 0.1 }}>
                <TextField
                  size="small"
                  value={entry6}
                  onChange={(e) => setEntry6(e.target.value)}
                  variant="outlined"
                  label="p. cessione netto prodotto"
                  fullWidth
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    },
                  }}
                />
              </TableCell>
              <TableCell sx={{ p: 0.1 }}>
                <TextField
                  size="small"
                  value={entry7}
                  onChange={(e) => setEntry7(e.target.value)}
                  variant="outlined"
                  label="p. cessione netto espo"
                  fullWidth
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    },
                  }}
                />
              </TableCell>
              <TableCell sx={{ p: 0.1 }}>
                <TextField
                  size="small"
                  value={entry8}
                  onChange={(e) => setEntry8(e.target.value)}
                  variant="outlined"
                  label="quantità"
                  fullWidth
                />
              </TableCell>
              <TableCell sx={{ p: 0.1, textAlign: "center" }}>
                <Button
                  size="small"
                  onClick={() =>
                    createData(
                      entry1,
                      entry2,
                      entry3,
                      entry4,
                      entry5,
                      entry6,
                      entry7,
                      entry8
                    )
                  }
                >
                  CREA RIGA
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PricingTable;
