import React, { useEffect, useState } from "react";

import { Box, Switch, TextField, Typography } from "@mui/material";

const Textinput = ({ componentId, saveComponent }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [obligatory, setObligatory] = useState(false);

  const handleSave = () => {
    saveComponent(componentId, title, description, [], obligatory);
  };

  useEffect(() => {
    handleSave();
  }, [title, description, obligatory]);

  return (
    <>
      <Typography variant="h5"> Casella testuale</Typography>
      Consente una risposta libera
      <TextField
        fullWidth
        label="Titolo"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        style={{ marginTop: 8 }}
      />
      <TextField
        fullWidth
        label="Descrizione"
        multiline
        rows={2}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{ marginTop: 16 }}
      />
      <Typography>È obbligatorio rispondere a questa domanda?</Typography>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Typography>No</Typography>
        <Switch onChange={(e) => setObligatory(e.target.checked)} />
        <Typography>Si</Typography>
      </Box>
    </>
  );
};

export default Textinput;
