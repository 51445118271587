import { Button } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const PharmaciesUploadBtn = () => {
    const navigate = useNavigate();

  return (
    <Button
      fullWidth={(isMobile || window.innerWidth <= 785) && true}
      onClick={() => navigate("/pharmacies_upload")}
    >
      CARICA FOTO
    </Button>
  );
};

export default PharmaciesUploadBtn;
