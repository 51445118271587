import React from "react";
import NavBar from "../../../../components/navbar/NavBar";
import ProfileData from "../../../../components/users/ProfileData";

const AlphegaProfile = () => {
  return (
    <>
      <NavBar type="4719" />
      <ProfileData user_type="Alphega_Centrale" />
    </>
  );
};

export default AlphegaProfile;
