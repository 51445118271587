import { Grid } from "@mui/material";
import { PulseLoader } from "react-spinners";

const LoadingSpinner = () => {
  return (
    <Grid>
      <PulseLoader color="#83c4ff" margin="30px" />
    </Grid>
  );
};

export default LoadingSpinner;
