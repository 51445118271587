import React from "react";
import NavBar from "../../../../components/navbar/NavBar";
import ProfileData from "../../../../components/users/ProfileData";

const PharmaciesProfile = () => {
  return (
    <>
      <NavBar type="8632" />
      <ProfileData user_type="Farmacia" />
    </>
  );
};

export default PharmaciesProfile;
