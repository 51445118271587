import React from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../../../components/navbar/NavBar";
import UsersDetail from "../../../../components/users/UsersDetail";

const AlphegaUsersDetail = () => {
  const { idUser } = useParams();  
  return (
    <>
      <NavBar type="4719" />

      <UsersDetail userId = {idUser}/>
    </>
  );
};

export default AlphegaUsersDetail;
