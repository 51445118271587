import { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetElemDataById from "../../../hooks/useGetElemDataById";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

const Update = ({ handleOpen, open, id }) => {
  const table = "Promotion_Components"
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const prev = useGetElemDataById(table, id,  "id");

  const [selectedpromotion_id, setSelectedpromotion_id] = useState();
  const [selectedinput_type_id, setSelectedinput_type_id] = useState();
  const promotionsTemp = useGetUnlimitedListOf("Promotions", "", "list");
  const promotionsData = promotionsTemp.listElements;

  const input_typesTemp = useGetUnlimitedListOf("Input_Types", "", "list");
  const input_typesData = input_typesTemp.listElements;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

useEffect(() => {
    if (prev) {
      if(prev.promotion_id) {
        setSelectedpromotion_id(prev.promotion_id);
        setValue("promotion_id", prev.promotion_id);
      }
      if(prev.input_type_id) {
        setSelectedinput_type_id(prev.input_type_id);
        setValue("input_type_id", prev.input_type_id);
      }
    }
  }, [prev]);

  const update = async (data) => {
if (!data.promotion_id?.trim() || !data.input_type_id?.trim() || !data.title?.trim() || !data.options?.trim() || !data.order?.trim()) {
    window.alert("promotion_id, input_type_id, title, options, order non possono essere vuoti!");
    return;
  }


    const nullColumns = Object.keys(data)
    .filter(key => data[key] === null || data[key] === "")
    .join("|");

    const deletedData = data;
    delete deletedData.id;
    try {
      const updatedData = {
				id: prev.id,
        new_values: deletedData,
        str_list_null: nullColumns,
      };

      console.log(updatedData);

      const response = await axiosPrivate.put(`/${table}/update`, updatedData);
      if (response) {
        window.alert("Modifica avvenuta con successo!");
        navigate(0);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/unauthorized", {
          state: { from: location },
          replace: true,
        });
      } else {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };

  if (prev.length !== 0) {
    return (
      <>

        <Modal open={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ mb: 2 }}
            >
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Aggiorna l'elemento con id {id} alla tabella {table}
              </Typography>
              <Button
                color="error"
                onClick={() => handleOpen(false, "mod")}
                sx={{
                  maxWidth: "30px",
                  maxHeight: "30px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            {/* Autocomplete per il campo promotion_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={promotionsData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedpromotion_id
                  ? promotionsData.find((promotion) => promotion.id === selectedpromotion_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedpromotion_id(newValue.id);
                  setValue("promotion_id", newValue.id);
                } else {
                  setSelectedpromotion_id(null);
                  setValue("promotion_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select promotion_id"
                  placeholder="promotion_id"
                  error={!!errors.promotion_id}
                  helperText={
                    errors.promotion_id ? "Il campo promotion_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
            {/* Autocomplete per il campo input_type_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={input_typesData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedinput_type_id
                  ? input_typesData.find((promotion) => promotion.id === selectedinput_type_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedinput_type_id(newValue.id);
                  setValue("input_type_id", newValue.id);
                } else {
                  setSelectedinput_type_id(null);
                  setValue("input_type_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select input_type_id"
                  placeholder="input_type_id"
                  error={!!errors.input_type_id}
                  helperText={
                    errors.input_type_id ? "Il campo input_type_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
						{Object.entries(prev)
              .filter(([key]) => true && key !== "id" && key !== "promotion_id" && key !== "input_type_id" && key !== "obligatory")
              .map(([key, value], index) => (
                <TextField
                  key={index}
                  placeholder={key}
                  defaultValue={value}
                  sx={{ width: "50%", m: 1 }}
                  {...register(key)}
                />
              ))}
{/* switch per campo obligatory */}
<Controller
      name="obligatory"
      control={control}
      defaultValue={prev.obligatory}
      render={({ field }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography sx={{ mr: 2 }}>obligatory:</Typography>
          <Switch color="primary" {...field} checked={field.value} />
        </Box>
      )}
    />

            <Button onClick={handleSubmit(update)}>SALVA MODIFICHE</Button>
          </Box>
        </Modal>
      </>
    );
  }
};

export default Update;