import { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetElemDataById from "../../../hooks/useGetElemDataById";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

const Update = ({ handleOpen, open, id }) => {
  const table = "Cluster_Elements"
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const prev = useGetElemDataById(table, id,  "");

  const [selectedcluster_centroid_id, setSelectedcluster_centroid_id] = useState();
  const [selecteduser_id, setSelecteduser_id] = useState();
  const cluster_centroidsTemp = useGetUnlimitedListOf("Cluster_Centroids", "", "list");
  const cluster_centroidsData = cluster_centroidsTemp.listElements;

  const usersTemp = useGetUnlimitedListOf("Users", "", "list");
  const usersData = usersTemp.listElements;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

useEffect(() => {
    if (prev) {
      if(prev.cluster_centroid_id) {
        setSelectedcluster_centroid_id(prev.cluster_centroid_id);
        setValue("cluster_centroid_id", prev.cluster_centroid_id);
      }
      if(prev.user_id) {
        setSelecteduser_id(prev.user_id);
        setValue("user_id", prev.user_id);
      }
    }
  }, [prev]);

  const update = async (data) => {
if (!data.cluster_centroid_id?.trim() || !data.user_id?.trim()) {
    window.alert("cluster_centroid_id, user_id non possono essere vuoti!");
    return;
  }


    const nullColumns = Object.keys(data)
    .filter(key => data[key] === null || data[key] === "")
    .join("|");

    const deletedData = data;
    delete deletedData.id;
    try {
      const updatedData = {
				cluster_centroid_id: prev.cluster_centroid_id,
				user_id: prev.user_id,

        new_values: deletedData,
        str_list_null: nullColumns,
      };

      console.log(updatedData);

      const response = await axiosPrivate.put(`/${table}/update`, updatedData);
      if (response) {
        window.alert("Modifica avvenuta con successo!");
        navigate(0);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/unauthorized", {
          state: { from: location },
          replace: true,
        });
      } else {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };

  if (prev.length !== 0) {
    return (
      <>

        <Modal open={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ mb: 2 }}
            >
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Aggiorna l'elemento con id {id} alla tabella {table}
              </Typography>
              <Button
                color="error"
                onClick={() => handleOpen(false, "mod")}
                sx={{
                  maxWidth: "30px",
                  maxHeight: "30px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            {/* Autocomplete per il campo cluster_centroid_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={cluster_centroidsData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedcluster_centroid_id
                  ? cluster_centroidsData.find((cluster) => cluster.id === selectedcluster_centroid_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedcluster_centroid_id(newValue.id);
                  setValue("cluster_centroid_id", newValue.id);
                } else {
                  setSelectedcluster_centroid_id(null);
                  setValue("cluster_centroid_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select cluster_centroid_id"
                  placeholder="cluster_centroid_id"
                  error={!!errors.cluster_centroid_id}
                  helperText={
                    errors.cluster_centroid_id ? "Il campo cluster_centroid_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
            {/* Autocomplete per il campo user_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={usersData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selecteduser_id
                  ? usersData.find((user) => user.id === selecteduser_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelecteduser_id(newValue.id);
                  setValue("user_id", newValue.id);
                } else {
                  setSelecteduser_id(null);
                  setValue("user_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select user_id"
                  placeholder="user_id"
                  error={!!errors.user_id}
                  helperText={
                    errors.user_id ? "Il campo user_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
						{Object.entries(prev)
              .filter(([key]) => true && key !== "cluster_centroid_id" && key !== "user_id")
              .map(([key, value], index) => (
                <TextField
                  key={index}
                  placeholder={key}
                  defaultValue={value}
                  sx={{ width: "50%", m: 1 }}
                  {...register(key)}
                />
              ))}
            <Button onClick={handleSubmit(update)}>SALVA MODIFICHE</Button>
          </Box>
        </Modal>
      </>
    );
  }
};

export default Update;