import React from "react";

import { isMobile } from "react-device-detect";

import { Box } from "@mui/material";

import InfoBar from "../layout/InfoBar";
import MobileNav from "./supports/MobileNav";
import DesktopNav from "./supports/DesktopNav";
import useGetCurrentUserData from "../../hooks/auth/useGetCurrentUserData";

const NavBar = ({ type }) => {
  const curentUserData = useGetCurrentUserData();

  return (
    <>
      {!isMobile && <InfoBar userData={curentUserData} />}
      <Box
        sx={{
          mt: -1,
          mb: 2,
          pt: 1,
          position: !isMobile && "sticky",
          top: 0,
          zIndex: 10,
          background: "white",
          boxShadow: 2,
          maxHeight: 125,
        }}
      >
        {isMobile || window.innerWidth <= 785 ? (
          <MobileNav type={type} userData={curentUserData} />
        ) : (
          <DesktopNav type={type} userData={curentUserData} />
        )}
      </Box>
    </>
  );
};

export default NavBar;
