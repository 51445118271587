import React, { useEffect, useState } from "react";

import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";

import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import {
  Box,
  Button,
  Container,
  Grid2,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

import Map from "./read_input/Map";
import Link from "./read_input/Link";
import TextInput from "./read_input/TextInput";
import PricingTable from "./read_input/PricingTable";
import QuantitySelect from "./read_input/QuantitySelect";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import PromotionsModifyModal from "./PromotionsModifyModal";
import PromotionAttachmentModal from "./PromotionAttachmentModal";
import PromotionAssignmentModal from "./PromotionAssignmentModal";
import CheckboxSingleInput from "./read_input/CheckboxSingleInput";
import CheckboxMultipleInput from "./read_input/CheckboxMultipleInput";
import useGetPromotionFiles from "../../hooks/promotions/useGetPromotionFiles";
import useGetCompleteDetails from "../../hooks/promotions/useGetCompleteDetails";
import Images from "./read_input/Images";
import DateTime from "./read_input/DateTime";
import Text from "./read_input/Text";

const PromotionsDetail = ({ promotionId, user }) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { idUser } = useParams();
  let showButton = false;
  let loaded = false;
  const all = useGetCompleteDetails(promotionId, idUser);
  const file = useGetPromotionFiles(promotionId);

  const [hasError, setHasError] = useState(false); // New state for managing errors

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [choicesList, setChoicesList] = useState([]);
  let [componentsList, setComponentsList] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [answersObj, setAnswersObj] = useState([]);
  const [assignment, setAssignment] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [promotionTypeId, setPromotionTypeId] = useState();
  const [promotionType, setTypologyType] = useState();

  const [obligatoryCount, setObligatoryCount] = useState(0);
  const [obligatoryAnswers, setObligatoryAnswers] = useState({});

  const [coverId, setCoverId] = useState();
  const [coverFile, setCoverFile] = useState();
  const [expandCover, setExpandCover] = useState(false);

  const [openAttachment, setOpenAttachment] = useState(false);
  const [openModify, setOpenModify] = useState(false);
  const [openAssignment, setOpenAssignment] = useState(false);

  useEffect(() => {
    if (all) {
      if (all.promotion_components) {
        setComponentsList(all.promotion_components || []);
      }

      if (all.promotion_assignments && all.promotion_assignments.length > 0) {
        setAssignment(all.promotion_assignments || []);
      }

      if (all.promotion_answers && all.promotion_answers.length > 0) {
        setAnswers(all.promotion_answers[0] || []);
      }

      if (all.promotion) {
        setTitle(all.promotion.title || "");
        setDescription(all.promotion.description || "");
        setStartDate(all.promotion.start || "");
        setEndDate(all.promotion.end || "");
        setPromotionTypeId(all.promotion.typology_id);
        setTypologyType(all.promotion.typology);
      }
    }
    if (answers && answers.choices) {
      try {
        const parsedAnswers = JSON.parse(answers.choices);
        setAnswersObj(parsedAnswers);
      } catch (error) {
        console.error("Error parsing answers.choices:", error);
        setAnswersObj(null);
      }
    }
    if (file) {
      // eslint-disable-next-line
      file.map((elem) => {
        if (elem.file_type_id === 3) {
          setCoverId(elem.id);
        }
      });
    }
  }, [all, answers, file]);

  useEffect(() => {
    let obligatory = 0;
    let obligatoryAnswersInit = {};
    componentsList.forEach((element) => {
      if (element.obligatory) {
        obligatory++;
        obligatoryAnswersInit[element.id] = element.answer?.length > 0;
      }
    });
    setObligatoryCount(obligatory);
    setObligatoryAnswers(obligatoryAnswersInit);
  }, [componentsList]);

  useEffect(() => {
    const allObligatoryAnswered = Object.values(obligatoryAnswers).every(
      (answer) => answer === true
    );
    setDisableSubmit(!allObligatoryAnswered || hasError);
  }, [obligatoryAnswers, hasError]);

  const handleOpen = (type) => {
    if (type === "modify") setOpenModify(true);
    else setOpenAttachment(true);
  };

  const handleAnswers = (data) => {
    let updatedChoicesList = [...choicesList];
    let found = false;

    updatedChoicesList = updatedChoicesList.map((choice) => {
      if (choice.componentId === data.componentId) {
        found = true;
        return { ...choice, answer: data.answer };
      }
      return choice;
    });
    if (!found) {
      updatedChoicesList.push(data);
    }
    setChoicesList(updatedChoicesList);

    if (obligatoryAnswers.hasOwnProperty(data.componentId)) {
      setObligatoryAnswers((prev) => ({
        ...prev,
        [data.componentId]: data.answer.length > 0,
      }));
    }
  };

  const handleSubmit = async (subscribed) => {
    try {
      let resultString = JSON.stringify(choicesList);
      const result = await axiosPrivate.post("/Promotion_Answers/create", {
        promotion_assignment_id: assignment[0].id,
        subscribed: subscribed,
        choices: resultString,
      });
      if (result) {
        window.alert("Risposte inviate con successo!");
        navigate(-1);
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  const jsonSort = (elementsJson) => {
    elementsJson.sort((a, b) => {
      return a.order - b.order;
    });
  };

  const parseComponentOptions = (components) => {
    return components.map((component) => {
      if (typeof component.options === "string") {
        try {
          component.options = JSON.parse(component.options);
        } catch (error) {
          console.error(
            `Error parsing JSON for component ID ${component.id}:`,
            error
          );
        }
      }
      return component;
    });
  };

  const updateComponentAnswers = (components, answers) => {
    return components.map((component) => {
      if (
        assignment[0].assignment_state !== "Non inviata" &&
        assignment[0].assignment_state !== "Inviata"
      ) {
        component.disabled = true;
        showButton = true;
        if (answersObj.length !== 0) {
          answersObj.forEach((answer) => {
            if (answer.componentId === component.id) {
              component.answer = answer.answer;
            }
          });
        }
      }
      return component;
    });
  };

  const handleFile = async (idFile, type, fileName) => {
    try {
      const response = await axiosPrivate.get(
        `/Promotion_Attachments/get_file_by_id?id=${idFile}`,
        { responseType: "arraybuffer" }
      );
      // Verifica se la richiesta è per un'immagine (supponendo che tu possa identificarla in base al tipo di contenuto)
      const isImageRequest =
        response.headers["content-type"].startsWith("image/");

      if (type === "cover") {
        if (isImageRequest) {
          // Se è un'immagine, visualizzala sulla pagina React
          const base64Image = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          const imageURL = `data:${response.headers["content-type"]};base64,${base64Image}`;

          if (imageURL != null) {
            setCoverFile(imageURL);
          }
        }
      } else {
        // Se non è una cover, gestisci il download del file
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const blobURL = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = blobURL;
        downloadLink.download = fileName;
        downloadLink.style.display = "none";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(blobURL);
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 400) {
        window.alert("Username o password mancante!");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        // window.alert("Errore. Riprova"); crea errore in 404
      }
    }
  };

  //TODO cambiare chiamata
  const onExport = async () => {
    try {
      const response = await axiosPrivate.get(
        `/Promotions/export_data?id=${promotionId}`,
        { responseType: "blob" }
      );
      if (response) {
        console.log(response.headers);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        if (!response.headers["content-disposition"]) {
          window.alert("Errore nel file. Riprova.");
          return;
        }
        const fileName = response.headers["content-disposition"]
          .split("filename=")[1]
          .replace(/['"]/g, "");
        console.log(fileName);
        const blobURL = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = blobURL;
        downloadLink.download = fileName;
        downloadLink.style.display = "none";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(blobURL);
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 400) {
        window.alert("Username o password mancante!");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  componentsList = parseComponentOptions(componentsList);
  if (idUser || user === "Farmacia") {
    componentsList = updateComponentAnswers(componentsList, answersObj);
    jsonSort(componentsList);
  }
  loaded = true;

  return (
    <Container component={Paper} sx={{ py: 1, mt: -1 }}>
      {loaded ? (
        <>
          <Box sx={{ borderBottom: "1px solid #ddd", mb: 3 }}>
            {(idUser || answers.length !== 0) && (
              <Box
                sx={{
                  background:
                    user === "Farmacia"
                      ? assignment[0].assignment_state === "Accettata" ||
                        assignment[0].assignment_state === "Foto caricate"
                        ? "#009534"
                        : assignment[0].assignment_state === "Rifiutata"
                        ? "red"
                        : null
                      : null,
                  p: 1.5,
                  borderRadius: 2,
                  boxShadow: 2,
                  my: 1,
                }}
              >
                {user === "Farmacia" && (
                  <Typography
                    variant="h6"
                    sx={{ color: "white", fontSize: 16 }}
                  >
                    {" "}
                    {assignment[0].assignment_state === "Accettata" ||
                    assignment[0].assignment_state === "Foto caricate"
                      ? "Hai aderito alla "
                      : assignment[0].assignment_state === "Rifiutata" &&
                        "Hai rifiutato la "}
                    comunicazione in data{" "}
                    {new Date(answers.created_at).toLocaleString("it-IT", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: false,
                    })}
                  </Typography>
                )}
                {user === "Alphega_Centrale" && assignment.length > 0 && (
                  <>
                    <Typography>
                      FARMACIA: {assignment[0].business_name}
                    </Typography>
                    <Typography>
                      STATO: {assignment[0].assignment_state}
                    </Typography>
                    {(assignment[0].assignment_state === "Accettata" ||
                      assignment[0].assignment_state === "Foto caricate") && (
                      <Typography>
                        DATA ACCETTAZIONE COMUNICAZIONE:{" "}
                        {new Date(answers.created_at).toLocaleString("it-IT", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: false,
                        })}
                      </Typography>
                    )}
                    {assignment[0].assignment_state === "Rifiutata" && (
                      <Typography>
                        DATA RIFIUTO COMUNICAZIONE:{" "}
                        {new Date(answers.created_at).toLocaleString("it-IT", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: false,
                        })}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            )}
            <Grid2
              sx={{
                flexGrow: 1,
                justifyItems: "stretch",
              }}
              container
            >
              <Grid2
                item
                sx={{
                  width: expandCover ? "100%" : isMobile ? "50%" : "25%",
                  mx: "auto",
                  my: 1,
                  pr: isMobile ? 0 : 2,
                }}
              >
                {coverId &&
                  (() => {
                    console.log("QUA");
                    handleFile(coverId, "cover");
                    if (coverFile)
                      return (
                        <Tooltip
                          title={expandCover ? "Riduci" : "Espandi"}
                          followCursor
                          arrow
                          sx={{
                            border: "1px solid #ddd",
                          }}
                        >
                          <Button onClick={() => setExpandCover(!expandCover)}>
                            <img
                              src={coverFile}
                              alt="cover_image"
                              style={{ width: expandCover ? "100%" : "80%" }}
                            />
                          </Button>
                        </Tooltip>
                      );
                  })()}
              </Grid2>
              <Grid2
                item
                sx={{ width: isMobile || expandCover ? "100%" : "50%" }}
              >
                Categoria: <b>{promotionType}</b>
                <Typography variant="h4" sx={{ mt: 1 }}>
                  {title}
                </Typography>
                <Typography sx={{ mb: 1 }}>{description}</Typography>
              </Grid2>
              <Grid2
                item
                sx={{
                  width: isMobile || expandCover ? "100%" : "25%",
                  my: 2,
                  pl: isMobile ? 0 : 2,
                }}
              >
                {file.length !== 0 && (
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleOpen("attachment")}
                    endIcon={<FileDownloadIcon sx={{ fontSize: 20 }} />}
                    sx={{ mb: 1 }}
                    size="large"
                  >
                    Visualizza {file.length} allegat
                    {file.length === 1 ? "o" : "i"}
                  </Button>
                )}

                {user === "Alphega_Centrale" && !idUser && (
                  <Box display={"flex"} flexDirection={"column"}>
                    <Button
                      variant="contained"
                      color="warning"
                      sx={{ mb: 1 }}
                      onClick={() => setOpenModify(true)}
                      endIcon={<EditIcon />}
                      size="small"
                    >
                      Modifica comunicazione
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ mb: 1 }}
                      onClick={() => {
                        navigate(
                          `/../../../alphega_centrale/alphega_promotions/${promotionId}/alphega_promotion_assignment/`
                        );
                      }}
                      endIcon={<SendIcon />}
                      size="small"
                    >
                      Invia comunicazione
                    </Button>
                    <Button
                      variant="contained"
                      color="info"
                      sx={{ mb: 1 }}
                      onClick={() => setOpenAssignment(true)}
                      endIcon={<LocalPharmacyIcon />}
                      size="small"
                      disabled={assignment.length === 0 ? true : false}
                    >
                      {assignment.length === 0
                        ? "Non è stata ancora inviata"
                        : `Inviata a ${assignment.length} farmaci${
                            assignment.length === 1 ? "a" : "e"
                          }`}
                    </Button>
                    <Button
                      variant="contained"
                      color="info"
                      sx={{ mb: 1 }}
                      onClick={() => onExport()}
                      endIcon={
                        assignment.length === 0 ? (
                          <CloseIcon />
                        ) : (
                          <DownloadIcon />
                        )
                      }
                      size="small"
                      disabled={assignment.length === 0 ? true : false}
                    >
                      {assignment.length === 0
                        ? "Nessun dato esportabile"
                        : "Esporta dati farmacie (Excel)"}
                    </Button>
                  </Box>
                )}
              </Grid2>
            </Grid2>
          </Box>
          {openModify && (
            <PromotionsModifyModal
              promotionId={promotionId}
              open={openModify}
              onClose={() => setOpenModify(false)}
              title={title}
              description={description}
              type={promotionTypeId}
              startDate={startDate}
              endDate={endDate}
              file={file}
            />
          )}

          {openAttachment && (
            <PromotionAttachmentModal
              open={openAttachment}
              onClose={() => setOpenAttachment(false)}
              file={file}
              onFile={handleFile}
            />
          )}

          {openAssignment && (
            <PromotionAssignmentModal
              open={openAssignment}
              onClose={() => setOpenAssignment(false)}
              assignment={assignment}
              promotionId={promotionId}
            />
          )}

          {componentsList.length > 0 ? (
            componentsList.map((element, index) => {
              let converted_options = element.options;
              // .replace(/'(\w+)':/g, '"$1":') // Sostituisce 'key': con "key":
              // .replace(/:\s*'(.*?)',/g, ': "$1",') // Sostituisce : 'value' con : "value"
              // .replace(/\\\\/g, "\\") // Rimuove l'escape di prima
              console.log(typeof converted_options);
              const jsonOptions = converted_options; // options è una stringa, per accedere alle chiavi lo converto in JSON

              return (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography variant="h5" sx={{ color: "#009534" }}>
                    <b>
                      {element.title}{" "}
                      {element.obligatory && (
                        <Tooltip
                          title="* CAMPO OBBLIGATORIO"
                          followCursor
                          arrow
                        >
                          *
                        </Tooltip>
                      )}
                    </b>
                  </Typography>
                  {element.input_type_id === 1 && (
                    <TextInput
                      description={jsonOptions.description}
                      componentId={element.id}
                      handleAnswers={handleAnswers}
                      answer={element.answer}
                      disabled={element.disabled}
                      obligatory={element.obligatory}
                    />
                  )}
                  {element.input_type_id === 2 && (
                    <CheckboxSingleInput
                      description={jsonOptions.description}
                      componentId={element.id}
                      options={jsonOptions.choices}
                      handleAnswers={handleAnswers}
                      answer_c={element.answer}
                      disabled={element.disabled}
                      obligatory={element.obligatory}
                    />
                  )}
                  {element.input_type_id === 3 && (
                    <CheckboxMultipleInput
                      description={jsonOptions.description}
                      componentId={element.id}
                      options={jsonOptions.choices}
                      handleAnswers={handleAnswers}
                      answer={element.answer}
                      disabled={element.disabled}
                      obligatory={element.obligatory}
                    />
                  )}
                  {element.input_type_id === 4 && (
                    <Link description={jsonOptions.description} />
                  )}
                  {element.input_type_id === 5 && (
                    <QuantitySelect
                      description={jsonOptions.description}
                      componentId={element.id}
                      options={jsonOptions.choices}
                      handleAnswers={handleAnswers}
                      answer={element.answer}
                      disabled={element.disabled}
                      obligatory={element.obligatory}
                      setHasError={setHasError}
                    />
                  )}
                  {element.input_type_id === 6 && (
                    <Map
                      description={jsonOptions.description}
                      options={jsonOptions.choices}
                    />
                  )}
                  {element.input_type_id === 7 && (
                    <PricingTable
                      description={jsonOptions.description}
                      componentId={element.id}
                      options={jsonOptions.choices}
                      handleAnswers={handleAnswers}
                      answer={element.answer}
                      disabled={element.disabled}
                      obligatory={element.obligatory}
                      setHasError={setHasError}
                    />
                  )}
                  {element.input_type_id === 8 && (
                    <DateTime
                      description={jsonOptions.description}
                      options={jsonOptions.choices}
                    />
                  )}
                  {element.input_type_id === 9 && (
                    <Images
                      componentId={element.id}
                      options={jsonOptions.choices}
                    />
                  )}
                  {element.input_type_id === 10 && (
                    <Text
                      description={jsonOptions.description}
                      componentId={element.id}
                      handleAnswers={handleAnswers}
                      answer={element.answer}
                      disabled={element.disabled}
                      obligatory={element.obligatory}
                    />
                  )}
                </Box>
              );
            })
          ) : (
            <Typography>No elements available.</Typography>
          )}
        </>
      ) : (
        <Typography>Loading...</Typography>
      )}
      {!showButton && user !== "Alphega_Centrale" && (
        <Box
          sx={{
            display: isMobile ? "block" : "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            type="submit"
            onClick={() => handleSubmit(true)}
            disabled={disableSubmit}
            variant="contained"
            sx={{ mb: 4, width: isMobile ? "100%" : "20%" }}
          >
            Accetta
          </Button>
          <Button
            type="submit"
            color="error"
            onClick={() => {
              if (
                window.confirm(
                  "La comunicazione verrà contassegnata come rifiutata. Procedere?"
                )
              )
                handleSubmit(false);
            }}
            variant="contained"
            sx={{ mb: 4, width: isMobile ? "100%" : "20%" }}
          >
            Rifiuta
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default PromotionsDetail;
