import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { TextField, Typography, Button } from "@mui/material";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Coordinate di default (sede aplhega)
const DEFAULT_LAT = 44.3205;
const DEFAULT_LNG = 9.3477;

// Funzione per ottenere l'indirizzo dalle coordinate
const getAddressFromCoordinates = async (lat, lng) => {
  const response = await fetch(
    `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
  );
  const data = await response.json();
  return data.address;
};

const buildSearchUrl = (street, city, postalcode) => {
  const baseUrl = "https://nominatim.openstreetmap.org/search.php";
  const params = new URLSearchParams();

  if (street) params.append("street", street);
  if (city) params.append("city", city);
  if (postalcode) params.append("postalcode", postalcode);

  params.append("format", "jsonv2");
  return `${baseUrl}?${params.toString()}`;
};

const getCoordinatesFromAddress = async (street, city, postalcode) => {
  const response = await fetch(buildSearchUrl(street, city, postalcode));
  const data = await response.json();

  if (data.length > 0) {
    return {
      lat: parseFloat(data[0].lat),
      lng: parseFloat(data[0].lon),
    };
  }
  return null;
};

const MapEvents = ({ onClick }) => {
  useMapEvents({
    click(event) {
      onClick(event);
    },
  });
  return null;
};

// Nuovo componente per aggiornare il centro della mappa
const UpdateMapCenter = ({ position }) => {
  const map = useMap();
  
  useEffect(() => {
    if (position) {
      map.flyTo(position, 13, { animate: true });  // Usa flyTo per un'animazione smooth
    }
  }, [position, map]);

  return null;
};

const MapSelect = ({ componentId, saveComponent }) => {
  const [address, setAddress] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([]);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [showMarker, setShowMarker] = useState(false);
  const [markerPlaced, setMarkerPlaced] = useState(false);
  const [center, setCenter] = useState([DEFAULT_LAT, DEFAULT_LNG])

  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [postalcode, setPostalcode] = useState("");
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  useEffect(() => {
    const fetchDefaultAddress = async () => {
      const addressData = await getAddressFromCoordinates(
        DEFAULT_LAT,
        DEFAULT_LNG
      );
      setAddress(addressData);
    };
    fetchDefaultAddress();
  }, []);

  useEffect(() => {
    setIsSearchEnabled(street.trim() !== "" && city.trim() !== "" && postalcode.trim() !== "");
  }, [street, city, postalcode]);

  const handleSave = () => {
    saveComponent(componentId, title, description, options, false);
  };

  useEffect(() => {
    handleSave();
  }, [title, description, options]);

  const handleRemoveMarker = () => {
    setShowMarker(false);
    setMarkerPosition(null);
    setOptions([]);
    setAddress(null);
    setMarkerPlaced(false);
    setTitle("");
    setCity("");
    setStreet("");
    setPostalcode("")
    handleSave();
  };

  const handleMapClick = async (event) => {
    const { lat, lng } = event.latlng;
    const addressData = await getAddressFromCoordinates(lat, lng);
    setAddress(addressData);
    setMarkerPosition({ lat, lng });
    setOptions([lat.toFixed(4), lng.toFixed(4)]);
    setCenter([lat, lng])
    setShowMarker(true);
    setMarkerPlaced(true);
    handleSave();
  };

  const handleSearch = async () => {
    const coordinates = await getCoordinatesFromAddress(
      street,
      city,
      postalcode
    );
    const desc = street + ", " + city + ", " + postalcode;
    setDescription(desc);
    if (coordinates) {
      setMarkerPosition(coordinates);
      setShowMarker(true);
      setMarkerPlaced(true);
      setOptions([coordinates.lat.toFixed(4), coordinates.lng.toFixed(4)]);
      const addressData = await getAddressFromCoordinates(
        coordinates.lat,
        coordinates.lng
      );
      setAddress(addressData);
      setCenter([coordinates.lat, coordinates.lng]);  // Imposta il nuovo centro
    } else {
      setAddress(null);
      setShowMarker(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Mappa</Typography>
        Crea un elemento che seleziona un luogo
        <TextField
          fullWidth
          label="Titolo"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ marginTop: 8, marginBottom: 8 }}
        />
        <Typography sx={{ mt: 3 }}>
          Inserisci tutti i parametri di ricerca per marcare la posizione sulla mappa
        </Typography>
        <TextField
          fullWidth
          label="Via"
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          style={{ marginTop: 8, marginBottom: 8 }}
        />
        <TextField
          fullWidth
          label="Città"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          style={{ marginTop: 8, marginBottom: 8 }}
        />
        <TextField
          fullWidth
          label="CAP"
          value={postalcode}
          onChange={(e) => setPostalcode(e.target.value)}
          style={{ marginTop: 8, marginBottom: 8 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={!isSearchEnabled}
          style={{ marginBottom: "10px" }}
        >
          Cerca Indirizzo
        </Button>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          {!markerPlaced
            ? ""
            : address
            ? `Via: ${address.road || "N/A"}, Città: ${
                address.city || address.town || address.village || "N/A"
              }`
            : "Indirizzo non disponibile"}
        </Typography>
        <Typography>
          Se la posizione sulla mappa non è precisa, è possibile spostare il
          marker a mano
        </Typography>
        <div style={{ position: "relative", height: "300px", width: "500px", zIndex: 1 }}>
          <MapContainer
            center={center}
            zoom={13}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapEvents onClick={handleMapClick} />
            
            {showMarker && markerPosition && (
              <Marker
                position={[markerPosition.lat, markerPosition.lng]}
              ></Marker>
            )}

            <UpdateMapCenter position={markerPosition} />
          </MapContainer>
        </div>
      </div>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleRemoveMarker}
        disabled={!showMarker}
        style={{ marginTop: "10px" }}
      >
        Rimuovi Marker
      </Button>
      <br />
    </>
  );
};

export default MapSelect;
