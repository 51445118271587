import { useEffect, useState } from "react";

import useAxiosPrivate from "../useAxiosPrivate";

const useGetCurrentUserData = () => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get("/Users", {
          withCredentials: true,
        });

        isMounted && setData(response.data.result);
      } catch (error) {
        window.alert("Si è verificato un problema. Riprova.");
      }
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [axiosPrivate]);

  return data;
};

export default useGetCurrentUserData;
