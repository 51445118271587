import React from "react";
import NavBar from "../../../../components/navbar/NavBar";

import PromotionEditor from "../../../../components/promotions/PromotionEditor";
import { Box, Typography } from "@mui/material";

const AlphegaCreatePromotion = () => {
  return (
    <>
      <NavBar type="4719" />
      <Box sx={{ px: "10%", mb: 1 }}>
        <Typography component="h1" variant="h5" sx={{ my: 3, mb: 1 }}>
          Nuova comunicazione
        </Typography>
      </Box>
      <PromotionEditor />
    </>
  );
};

export default AlphegaCreatePromotion;
