import React from "react";

import {
  Box,
} from "@mui/material";

import LogoPublic from "../../layout/LogoPublic";
import PharmaciesNavbar from "../buttons/PharmaciesNavbar";
import AlphegaNavbar from "../buttons/AlphegaNavbar";
import LogoutBtn from "../buttons/singleButtons/LogoutBtn";

const DesktopNav = ({type}) => {
  return (
    <Box
          sx={{
            height: "100px",
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
            px: "6.5%",
          }}
        >
          <Box sx={{ width: 200 }}>
            <LogoPublic />
          </Box>
          <Box sx={{ mt: 4 }}>
            {type === "8632" ? (
              <PharmaciesNavbar />
            ) : (
              type === "4719" && <AlphegaNavbar />
            )}
          </Box>
          <Box sx={{ mt: 4, width: 200, textAlign: "right" }}>
            <LogoutBtn />
          </Box>
        </Box>
  )
}

export default DesktopNav