import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { axiosPrivate } from "../../api/axios";

const CreateUser = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [internalCode, setInternalCode] = useState("");

  const handleCreate = async () => {
    const data = {};
    const usr_rb = {
      email: email,
    };
    const usr_anagr_rb = {};
    if (name !== "") usr_anagr_rb.name = name;
    if (surname !== "") usr_anagr_rb.surname = surname;
    if (businessName !== "") usr_anagr_rb.business_name = businessName;
    if (telephone !== "") usr_anagr_rb.telephone = telephone;
    if (internalCode !== "") usr_anagr_rb.customer_internal_code = internalCode;

    data.usr_rb = usr_rb;
    data.usr_anagr_rb = usr_anagr_rb;
    data.role = "Farmacia";

    console.log(data);

    try {
      const create = await axiosPrivate.post("/Users/create_complete", data);
      if (create) {
        window.alert("Utente creato con successo!");
        navigate(0);
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  return (
    <Container component={Paper} sx={{ pt: 1, px: 1, borderRadius: 3 }}>
      <Box
        display={"flex"}
        alignContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Creazione di un utente farmacia
        </Typography>
        <Typography variant="h5">Email*</Typography>
        <TextField
          variant="outlined"
          placeholder="esempio@farmacia.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2, width: "50%" }}
        />

        <Typography variant="h5" sx={{ mb: 1 }}>
          Nome
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2, width: "50%" }}
        />
        <Typography variant="h5" sx={{ mb: 1 }}>
          Cognome
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Cognome"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          sx={{ mb: 2, width: "50%" }}
        />
        <Typography variant="h5" sx={{ mb: 1 }}>
          Ragione sociale *
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Ragione sociale"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
          sx={{ mb: 2, width: "50%" }}
        />
        <Typography variant="h5" sx={{ mb: 1 }}>
          Telefono *
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Telefono"
          value={telephone}
          onChange={(e) => setTelephone(e.target.value)}
          sx={{ mb: 2, width: "50%" }}
        />
        <Typography variant="h5" sx={{ mb: 1 }}>
          Codice centralizzato *
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Codice centralizzato farmacia"
          value={internalCode}
          onChange={(e) => setInternalCode(e.target.value)}
          sx={{ mb: 2, width: "50%" }}
        />
        <Button
          fullWidth
          size="large"
          variant="contained"
          sx={{ my: 5 }}
          onClick={handleCreate}
          disabled={
            email !== "" &&
            telephone !== "" &&
            internalCode !== "" &&
            businessName !== ""
              ? false
              : true
          }
        >
          CREA FARMACIA
        </Button>
      </Box>
    </Container>
  );
};

export default CreateUser;
