import { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import useAxiosPrivate from "../useAxiosPrivate";

const useGetUserCompleteInfo = (userId, variant) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);

  if (typeof userId !== "undefined" && userId !== null && userId !== "") userId = "?id=" + userId;

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const infos = async () => {
      try {
        const response = await axiosPrivate.get(
          `/Users/get_complete_information${userId}`
        );
        if (isMounted) {
          setData(response.data?.result);
        }
      } catch (err) {
        if (err.response?.status === 401) {
          navigate("/unauthorized", {
            state: { from: location },
            replace: true,
          });
        } else {
          if(typeof variant === "undefined") // fatto per variant = "login" e "register"
            navigate("/*", { state: { from: location }, replace: true });
        }
      }
    };

    infos();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, location, navigate, userId]);

  return data;
};

export default useGetUserCompleteInfo;
