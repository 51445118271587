import { useSearchParams } from "react-router-dom";

const useCollectSearchParams = () => {
  const [searchParams] = useSearchParams();
  let frontendSearchParams = {};

  let PAGE = searchParams.get("page"); //page: numero pagina
  let LIMIT = searchParams.get("limit"); //limit: limite numero elementi per pagina
  let COMPLETE = searchParams.get("complete"); //complete: prende valori tabella con join o senza
  let WHERE_KEY = searchParams.get("where_key"); //where_key: campo della tabella da ricercare
  let WHERE_VALUE = searchParams.get("where_value"); //where_value: valore con cui fare condizione where
  let SEARCH_KEY = searchParams.get("search_key"); //search_key: campo della tabella sul quale effettuare un filtro like
  let SEARCH_VALUE = searchParams.get("search_value"); //search_value: valore con cui effettuare un filtro like
  let ORDER_KEY = searchParams.get("order_key"); //order_key: campo della tabella su cui effettuare un order_by
  let ORDER_DESC = searchParams.get("order_desc"); //order_desc: true per ordinamento discendente

  if (PAGE === null || parseInt(PAGE) === 0) {
    frontendSearchParams = {
      ...frontendSearchParams,
      page: 1,
    };
  } else {
    frontendSearchParams = {
      ...frontendSearchParams,
      page: parseInt(PAGE),
    };
  }

  if (LIMIT === null) {
    frontendSearchParams = {
      ...frontendSearchParams,
      limit: 20,
    };
  } else {
    frontendSearchParams = {
      ...frontendSearchParams,
      limit: LIMIT,
    };
  }

  if (COMPLETE !== null) {
    frontendSearchParams = {
      ...frontendSearchParams,
      complete: COMPLETE,
    };
  }

  if (WHERE_KEY !== null) {
    frontendSearchParams = {
      ...frontendSearchParams,
      where_key: WHERE_KEY,
    };
  }

  if (WHERE_VALUE !== null) {
    frontendSearchParams = {
      ...frontendSearchParams,
      where_value: WHERE_VALUE,
    };
  }

  if (SEARCH_KEY !== null) {
    frontendSearchParams = {
      ...frontendSearchParams,
      search_key: SEARCH_KEY,
    };
  }

  if (SEARCH_VALUE !== null) {
    frontendSearchParams = {
      ...frontendSearchParams,
      search_value: SEARCH_VALUE,
    };
  }

  if (ORDER_KEY !== null) {
    frontendSearchParams = {
      ...frontendSearchParams,
      order_key: ORDER_KEY,
    };
  }

  if (ORDER_DESC !== null) {
    frontendSearchParams = {
      ...frontendSearchParams,
      order_desc: ORDER_DESC,
    };
  }
  return {
    frontendParams: frontendSearchParams, // frontendSearchParams = i search params;
    specifiedParamsNumber: searchParams.size,
    //searchParams.size = numero di search params attualmente specificati nell'url (quindi quelli specificati dall'utente che utilizza la pagina).
    //Necessario in quanto frontendSearchParams quando l'url è vuoto contiene comunque due elementi (page=1 e limit=20).
    //Usato per attivare e disattivare il tasto reset filtri
  };
};

export default useCollectSearchParams;
