import React from "react";
import NavBar from "../../../../components/navbar/NavBar";
import PromotionAssignment from "../../../../components/promotions/PromotionAssignment";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

const AlphegaCreatePromotion = () => {
  const { idPromotion } = useParams();
  return (
    <>
      <NavBar type="4719" />
      <Box sx={{ px: "10%", mb: 1 }}>
        <Typography component="h1" variant="h5" sx={{ my: 3, mb: 1 }}>
          Seleziona le farmacie a cui inviare la comunicazione
        </Typography>
      </Box>
      <PromotionAssignment idPromotion={idPromotion} />
    </>
  );
};

export default AlphegaCreatePromotion;
