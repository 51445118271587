import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const PharmaciesHomeBtn = () => {
    const navigate = useNavigate();

  return (
    <Button fullWidth={(isMobile || window.innerWidth <= 785) && true} onClick={() => navigate("/pharmacies_home")}>
      HOME
    </Button>
  );
};

export default PharmaciesHomeBtn;
