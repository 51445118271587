import React from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../../../components/navbar/NavBar";
import PromotionsDetail from "../../../../components/promotions/PromotionsDetail";

const PharmaciesPromotionsDetail = () => {
  const { idPromotion } = useParams();
  return (
    <>
      <NavBar type="8632" />
      <PromotionsDetail promotionId={idPromotion} user="Farmacia" />
    </>
  );
};

export default PharmaciesPromotionsDetail;
