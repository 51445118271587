import React from "react";
import { useState } from "react";

import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import { Box, TextField } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Divider, Grid, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import NavBar from "../navbar/NavBar";
import LoadingSpinner from "../layout/LoadingSpinner";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useGetCurrentUserData from "../../hooks/auth/useGetCurrentUserData";

const PSW_MIN_LENGTH = 8;

const ProfileDataChangePsw = () => {
  const axiosPrivate = useAxiosPrivate();
  const userData = useGetCurrentUserData();

  var userType = "";
  switch (userData.role) {
    case "Admin":
      userType = "6957";
      break;

    // case "Broker":
    //   userType = "7304";
    //   break;

    // case "Client":
    //   userType = "8297";
    //   break;

    // case "Controller":
    //   userType = "2508";
    //   break;

    default:
      break;
  }

  const [isActive, setIsActive] = useState(false);
  const [pswInput, setPswInput] = useState("");
  const [rePswInput, setRePswInput] = useState("");
  const [oldPswInput, setOldPswInput] = useState("");
  let [loading, setLoading] = useState(false);
  // let [pswdsEqual, setPswdsEqual] = useState(false);
  let [pswdsOk, setPswdsOk] = useState(false);

  var psw = document.getElementById("new_password");
  var rePsw = document.getElementById("retyped-password");
  var oldPsw = document.getElementById("old_password");

  function showPswds() {
    if (psw?.type === "password" && rePsw?.type === "password") {
      setIsActive(true);
      psw.type = "text";
      rePsw.type = "text";
      oldPsw.type = "text";
    } else if (psw.type === "text" && rePsw.type === "text") {
      setIsActive(false);
      psw.type = "password";
      rePsw.type = "password";
      oldPsw.type = "password";
    }
  }

  function checkPswds() {
    if (psw?.value !== "" || rePsw?.value !== "") {
      if (
        psw?.value.length >= PSW_MIN_LENGTH &&
        rePsw?.value.length >= PSW_MIN_LENGTH &&
        oldPsw?.value.length >= PSW_MIN_LENGTH
      ) {
        if (psw?.value !== rePsw?.value) {
          // setPswdsEqual(false);
          setPswdsOk(false);
        } else {
          // setPswdsEqual(true);

          if (oldPsw?.value.length >= PSW_MIN_LENGTH) setPswdsOk(true);
          else setPswdsOk(false);
        }
      } else {
        // setPswdsEqual(false);
        setPswdsOk(false);
      }
    }
  }

  const handleSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();

    await axiosPrivate
      .post(
        "/auth/change_password",
        JSON.stringify({
          email: userData.email,
          old_password: oldPsw.value,
          new_password: psw.value,
        })
      )
      .then((response) => {
        if (response?.status === 200) {
          window.alert("Password cambiata");
          window.location.href = "/profilo";
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error: ", error);
        if (error.response?.status === 400) {
          window.alert(error.response.data?.detail);
        } else {
          window.alert("Si è verificato un problema. Riprova.");
        }
      });
  };

  return (
    <React.Fragment>
      <NavBar value={userType} />
      <Box
        pt={"75px"}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <h1>Modifica password</h1>
        <br />
        <Container maxWidth={"sm"}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              Inserisci la tua nuova password
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="h7">
              La password deve contenere minimo 8 caratteri.
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3, width: "100%" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    type="password"
                    id="new_password"
                    label="Nuova password"
                    autoComplete="new_password"
                    error={psw?.value.length < 8 && psw?.value.length > 0}
                    helperText={
                      psw?.value.length < 8 &&
                      psw?.value.length > 0 &&
                      "La password deve contenere almeno 8 caratteri."
                    }
                    value={pswInput}
                    onChange={(e) => {
                      setPswInput(e.target.value);
                      checkPswds();
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    type="password"
                    id="retyped-password"
                    label="Ripeti la nuova password"
                    autoComplete="retyped-password"
                    error={psw?.value !== rePsw?.value}
                    helperText={
                      psw?.value !== rePsw?.value &&
                      "Le password devono coincidere."
                    }
                    value={rePswInput}
                    onChange={(e) => {
                      setRePswInput(e.target.value);
                      checkPswds();
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button onClick={() => showPswds(!isActive)}>
                            {isActive ? (
                              <VisibilityOffIcon fontSize="large" />
                            ) : (
                              <VisibilityIcon fontSize="large" />
                            )}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Divider sx={{ my: 6 }} />
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    type="password"
                    id="old_password"
                    label="Inserisci la password attuale per confermare"
                    autoComplete="old_password"
                    value={oldPswInput}
                    onChange={(e) => {
                      setOldPswInput(e.target.value);
                      checkPswds();
                    }}
                  />
                </Grid>
              </Grid>
              {pswdsOk ? (
                loading ? (
                  <LoadingSpinner />
                ) : (
                  <Grid>
                    <Button
                      id="submitBtn"
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Invio
                    </Button>
                  </Grid>
                )
              ) : (
                <Grid>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled
                  >
                    Invio
                  </Button>
                  <Button
                    component={Link}
                    to="/profilo"
                    variant="contained"
                    color="error"
                    sx={{ mx: 2, width: "40%" }}
                  >
                    Annulla
                  </Button>
                </Grid>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default ProfileDataChangePsw;
