import React from "react";
import NavBar from "../../../../components/navbar/NavBar";
import Files from "../../../../components/files/Files";
import { Typography } from "@mui/material";
const AlphegaFiles = () => {
  return (
    <>
      <NavBar type="4719" />
      <Typography component="h1" variant="h5" sx={{ my: 3, mb: 1 }}>
        Archivio foto
      </Typography>
      <Files />
    </>
  );
};

export default AlphegaFiles;
