import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../components/navbar/NavBar"

const Home = () => {
  const navigate = useNavigate();

  return (
	<>
	  <Navbar/>
	  <Typography variant="h5" sx={{ m: 10 }}>
		Home
	  </Typography>
	  <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
		<Button sx={{m: 1, width: "25%"}} variant="outlined" onClick={() => navigate("/tables")}>
		  CRUD e tabelle
		</Button>
		<Button sx={{m: 1, width: "25%"}} variant="outlined" onClick={() => navigate("/profilo")}>
		  Profilo utente
		</Button>
	  </Box>
	</>
  );
};

export default Home;

	