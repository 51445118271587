import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const CheckboxSingleInput = ({
  description,
  componentId,
  options,
  handleAnswers,
  answer_c,
  disabled,
  obligatory,
}) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(answer_c || "");     //assegno o answer o "" se c'è o meno risposta
  }, [answer_c]);

  const handleChoice = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    const answerData = {
      componentId: componentId,
      answer: newValue,
      obligatory: obligatory, 
    };
    handleAnswers(answerData);
  };

  return (
    <Box
      sx={{
        mt: 1,
        mb: 6,
        mx: "5%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="body1" sx={{ mb: 1 }}>
        {description}
      </Typography>
      <FormControl>
        <RadioGroup value={value} onChange={handleChoice}>
          {options.map((option) => (
            <FormControlLabel
              key={option.label}
              control={<Radio />}
              disabled={disabled}
              value={option.label}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default CheckboxSingleInput;
