import { Box } from "@mui/material";
import React from "react";

const Images = ({ componentId, options }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: 300,
      }}
    >
      {options.map((option) => (
        <Box
          sx={{
            width:
              options.length === 1
                ? "100%"
                : options.length === 2
                ? "50%"
                : options.length === 3 && "33%",
          }}
        >
          <img
            src={`data:image/png;base64,${option.label}`}
            style={{
              objectFit: "contain",
              overflow: "hidden",
              width: "100%",
              height: "100%"
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Images;
