import React, { useEffect, useState } from "react";

import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";

import { Box, Button, Checkbox, FormControlLabel, Modal } from "@mui/material";

import useGetListOf from "../../../hooks/useGetListOf";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "95%" : "50%",
  height: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const UsersDetailDataNewClusterModal = ({
  open,
  onClose,
  actualClusters,
  userId,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [actualClustersIds, setActualClustersIds] = useState([]);
  const clusters = useGetListOf(
    "Cluster_Centroids",
    "limit=99999999",
    "list"
  ).listElements;

  useEffect(() => {
    if (actualClusters.length !== 0) {
      const clusterCentroidIds = actualClusters.map(
        (item) => item.cluster_centroid_id
      );
      setActualClustersIds(clusterCentroidIds);
    }
  }, [actualClusters]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const handleAddUserToCluster = async (cluster_id) => {
    try {
      const response = await axiosPrivate.post("/Cluster_Elements/create", {
        cluster_centroid_id: cluster_id,
        user_id: userId,
      });

      if (response) {
        setSearchParams("");
      }
    } catch (err) {
      if (!err?.response) {
        window.alert("Errore. Riprova.");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert(`Errore: ${err.response.data.detail}`);
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleClose}
          sx={{ mt: -4, position: "sticky", top: -20, zIndex: 100 }}
        >
          <b>Chiudi</b>
        </Button>
        <Box fullWidth sx={{ textAlign: "center" }}>
          Seleziona in quale insieme includere la farmacia
        </Box>
        <Box>
          {clusters?.map((cluster) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={actualClustersIds.includes(cluster.id)}
                  disabled={actualClustersIds.includes(cluster.id)}
                  onChange={() => handleAddUserToCluster(cluster.id)}
                />
              }
              label={cluster.title}
              sx={{
                width: isMobile ? "45%" : "30%",
                border: "1px solid #ddd",
                borderRadius: 2,
                m: 0.5,
                p: 0.5,
                "&:hover": { background: "#ddd" },
              }}
            />
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default UsersDetailDataNewClusterModal;
