import { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";


const Create = ({ handleOpen, open }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const table = "Promotion_Answers";

  const [selectedpromotion_assignment_id, setSelectedpromotion_assignment_id] = useState(null);
  const promotion_assignmentsTemp = useGetUnlimitedListOf("Promotion_Assignments", "", "list");
  const promotion_assignmentsData = promotion_assignmentsTemp.listElements;

  const columns = ["id", "promotion_assignment_id", "subscribed", "choices"];

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const create = async (formData) => {
if (formData.promotion_assignment_id === null || !formData.choices?.trim()) {
    window.alert("promotion_assignment_id, choices non possono essere vuoti!");
    return;
  }


    try {
      const response = await axiosPrivate.post(`/${table}/create`, formData);
      if (response) {
        window.alert("Creazione avvenuta con successo!");
        navigate(0);
      }
    } catch (err) {
       if (err.response?.status === 401) {
         navigate("/unauthorized", {
           state: { from: location },
           replace: true,
         });
       } else {
         navigate("/login", { state: { from: location }, replace: true });
       }
    }
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
          alignItems={"center"}
        >
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mb: 2 }}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Crea un nuovo elemento per la tabella {table}
            </Typography>
            <Button
              color="error"
              onClick={() => handleOpen(false, "create")}
              sx={{
                maxWidth: "30px",
                maxHeight: "30px",
                minWidth: "30px",
                minHeight: "30px",
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ width: "100%" }}
          >
            {/* Autocomplete per il campo promotion_assignment_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={promotion_assignmentsData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedpromotion_assignment_id
                  ? promotion_assignmentsData.find((promotion) => promotion.id === selectedpromotion_assignment_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedpromotion_assignment_id(newValue.id);
                  setValue("promotion_assignment_id", newValue.id);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select promotion_assignment_id"
                  placeholder="promotion_assignment_id"
                  error={!!errors.promotion_assignment_id}
                  helperText={
                    errors.promotion_assignment_id ? "Il campo promotion_assignment_id è obbligatorio" : ""
                  }
                />
              )}
            />
          </Box>

          {/* Altri campi */}
          {columns
            .filter((column) => column !=="id" && column !== "promotion_assignment_id" && column !== "subscribed")
            .map((column, index) => (
              <TextField
                key={index}
                placeholder={column}
                sx={{ width: "50%", m: 1 }}
                {...register(column)}
              />
            ))}

{/* switch per campo subscribed */}
<Controller
      name="subscribed"
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography sx={{ mr: 2 }}>subscribed:</Typography>
          <Switch color="primary" {...field} checked={field.value} />
        </Box>
      )}
    />


          <Button onClick={handleSubmit(create)}>CREA ENTRY</Button>
        </Box>
      </Modal>
    </>
  );
};

export default Create;