import React from "react";
import ElementsList from "../../../../components/ElementsList";
import { Box, Typography } from "@mui/material";
import NavBar from "../../../../components/navbar/NavBar";

const AlphegaUsersDeactivated = () => {
  return (
    <>
      <NavBar type="4719" />
      <Box sx={{ px: "10%", mb: 1 }}>
        <Typography component="h1" variant="h5" sx={{ my: 3, mb: 1 }}>
          Gestione utenti disabilitati
        </Typography>
      </Box>
      <ElementsList
        user="alphega_centrale"
        type="Users"
        variantSearchKeys="where_key=role_id&where_value=3&complete=true"
        variant="inactive"
      />
    </>
  );
};

export default AlphegaUsersDeactivated;
