import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import useAxiosPrivate from "./useAxiosPrivate";

const useGetElemDataById = (elemType, id) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [infos, setInfos] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const list = async () => {
      try {
        const response = await axiosPrivate.get(`/${elemType}/get/${id}`);

        isMounted && setInfos(response.data?.result); // && => se isMounted == true
      } catch (err) {
        if (err.response?.status === 401) {
          navigate("/unauthorized", {
            state: { from: location },
            replace: true,
          });
        } else {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    list();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, elemType, id, location, navigate]);

  return infos;
};

export default useGetElemDataById;