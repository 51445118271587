import React from "react";

import { Box, Typography } from "@mui/material";

import NavBar from "../../../../components/navbar/NavBar";
import ImagesUpload from "../../../../components/files/ImagesUpload";

const PharmaciesUpload = () => {
  return (
    <>
      <NavBar type="8632" />
      <Box sx={{ px: "10%", mb: 1 }}>
        <Typography component="h1" variant="h5">
          Upload fotografie
        </Typography>
        Qui puoi caricare le immagini per Alphega
      </Box>
      <ImagesUpload />
    </>
  );
};

export default PharmaciesUpload;
