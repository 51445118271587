import { useEffect, useState } from "react";

import { PulseLoader } from "react-spinners";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { Box, Grid } from "@mui/material";

import useAuth from "../../hooks/auth/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const RequireAuth = ({ allowedRoles }) => {
  const [check, setCheck] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useAuth();
  const [finish, setFinish] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const response = await axiosPrivate.post("/token/check_token", {
          roles: allowedRoles,
        });
        setCheck(response.data?.result);
        setFinish(true);
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    checkUser();
  }, [allowedRoles, axiosPrivate, location, navigate]);

  return (
    <div>
      {(() => {
        if (finish) {
          if (check) {
            return <Outlet />;
          } else {
            if (auth?.user !== "") {
              console.log("Unauthorized.");
              return (
                <Navigate
                  to="/unauthorized"
                  state={{ from: location }}
                  replace
                />
              );
            } else {
              console.log("Unauthenticated.");
              return (
                <Navigate to="/login" state={{ from: location }} replace />
              );
            }
          }
        } else {
          console.log("Loading . . .");
          return (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Box>
                <PulseLoader color="#83c4ff" margin="30px" />
              </Box>
            </Grid>
          );
        }
      })()}
    </div>
  );
};

export default RequireAuth;
