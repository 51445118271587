import React from "react";

import { Button, Box } from "@mui/material";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";

function createData(
  id,
  title,
  start,
  end,
  promotions_type
  //colonne tabella
) {
  return {
    id,
    title,
    start,
    end,
    promotions_type,
    //colonne tabella
  };
}

const PromotionsList = (promotions, userType, frontendParams) => {
  const navigate = useNavigate();
  const renderTableHeader = (header, title) => {
    if (
      frontendParams.order_key === header &&
      frontendParams.order_desc === "true"
    )
      return (
        <React.Fragment>
          <ArrowDropDownIcon />
          {title}
        </React.Fragment>
      );
    else if (
      frontendParams.order_key === header &&
      frontendParams.order_desc === "false"
    )
      return (
        <React.Fragment>
          <ArrowDropUpIcon />
          {title}
        </React.Fragment>
      );
  };

  const rows = [];

  for (let i = 0; i < promotions?.length; i++) {
    rows.push(
      createData(
        promotions[i]["promotions.id"],
        promotions[i]["promotions.title"],
        promotions[i]["promotions.start"],
        promotions[i]["promotions.end"],
        promotions[i]["promotion_typologies.description"]
      )
    );
  }

  const columns = [
    {
      field: "title",
      headerName: "Titolo",
      flex: 1,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            size="large"
            sx={{ textAlign: "left", justifyContent: "flex-start" }}
            onClick={() => navigate(`${params.row.id}`)}
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      field: "promotions_type",
      headerName: "Tipologia comunicazione",
      flex: 1,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            size="large"
            sx={{ textAlign: "left", justifyContent: "flex-start" }}
            onClick={() => navigate(`${params.row.id}`)}
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      field: "start",
      headerName: "Data inizio",
      flex: 1,
      minWidth: 110,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        const date = new Date(params.value);
        const pad = (n) => String(n).padStart(2, "0");
        const formattedDate = `${pad(date.getDate())}-${pad(
          date.getMonth() + 1
        )}-${date.getFullYear()} ${pad(date.getHours())}:${pad(
          date.getMinutes()
        )}:${pad(date.getSeconds())}`;

        return (
          <>
            <Box
              minWidth="85px"
              sx={{ borderLeft: "1px solid #ddd", fontSize: 12, ml: -1 }}
            >
              {formattedDate === "01-01-1970 01:00:00" ? "" : `📆 ${formattedDate}`}
            </Box>
          </>
        );
      },
    },
    {
      field: "end",
      headerName: "Data fine",
      flex: 1,
      minWidth: 110,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        const date = new Date(params.value);
        const pad = (n) => String(n).padStart(2, "0");
        const formattedDate = `${pad(date.getDate())}-${pad(
          date.getMonth() + 1
        )}-${date.getFullYear()} ${pad(date.getHours())}:${pad(
          date.getMinutes()
        )}:${pad(date.getSeconds())}`;

        return (
          <Box
            minWidth="85px"
            sx={{ borderLeft: "1px solid #ddd", fontSize: 12, ml: -1 }}
          >
            {formattedDate === "01-01-1970 01:00:00" ? "" : `📆 ${formattedDate}`}
          </Box>
        );
      },
    },
  ];
  return { rows: rows, columns: columns };
};

export default PromotionsList;
