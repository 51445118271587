import { useNavigate } from "react-router-dom";

import { Box, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const BackButton = (specificPath) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ textAlign: "left" }}>
      <Button
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        onClick={() => {
          if(typeof specificPath.value !== "undefined") //se viene specificato un determinato percorso vai a quel percorso
            navigate(specificPath.value);
          else if (typeof navigate(-1) === "undefined") //se non c'è un percorso "padre", chiudi la scheda
            window.close();
          else                                          //torna indietro di una pagina in tutti gli altri casi
            navigate(-1);
        }}
      >
        <b>Indietro</b>
      </Button>
    </Box>
  );
};

export default BackButton;
