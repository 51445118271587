import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import Update from "./Update";
import Delete from "./Delete";
import Create from "./Create";
import NavBar from "../../../components/navbar/NavBar";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

const Input_Types_Table = () => {
  const table = "Input_Types";
  const temp = useGetUnlimitedListOf(table, "", "list");
  const data = temp.listElements;
  const columns = ["id", "description"];

  const [modOpen, setModOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleOpen = (value, type) => {
    if (type === "mod") setModOpen(value);
    else setCreateOpen(value);
  };

  return (
    <>
      <NavBar />
      <Typography variant="h4" sx={{ m: 3 }}>
        Tabella {table}
      </Typography>
      <Box
        display={"flex"}
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
      >
        <TableContainer component={Paper} sx={{ width: "90%" }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index}>{column}</TableCell>
                ))}
                <TableCell> Azioni </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column, colIndex) => (
                    <TableCell key={colIndex}>{row[column]}</TableCell>
                  ))}
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setSelectedRowId(row.id);
                        handleOpen(true, "mod");
                      }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                    {modOpen &&
                      selectedRowId === row.id && ( 
                        <Update
                          handleOpen={handleOpen}
                          open={modOpen}
                          id={selectedRowId}
                        />
                      )}
                    <Delete id={row.id} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button sx={{ mt: 3 }} onClick={() => handleOpen(true, "create")}>
          <Typography> Aggiungi un elemento alla tabella </Typography>
          <AddIcon color="primary" />
        </Button>
        {createOpen && (
          <Create
            columns={columns}
            handleOpen={handleOpen}
            open={createOpen}
          />
        )}
      </Box>
    </>
  );
};

export default Input_Types_Table;