import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const QuantitySelect = ({
  description,
  componentId,
  options,
  handleAnswers,
  answer,
  disabled,
  obligatory,
  setHasError, 
}) => {
  const [localError, setLocalError] = useState(false);

  const handleChoice = (newValue) => {
    const min = parseInt(options[0].label);
    const max = parseInt(options[1].label);

    if (newValue < min || newValue > max) {
      setLocalError(true);
      setHasError(true);
      return;
    } else {
      setLocalError(false); 
      setHasError(false); 
    }

    const answerData = {
      componentId: componentId,
      answer: newValue,
      obligatory: obligatory,
    };
    handleAnswers(answerData);
  };

  useEffect(() => {
    const min = parseInt(options[0].label);
    const max = parseInt(options[1].label);
    if (answer < min || answer > max) {
      setHasError(true);
    }
  }, [answer, options, setHasError]);

  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
      <Typography>{description}</Typography>
      <Typography>Valore minimo: {options[0].label}</Typography>
      <Typography>Valore massimo: {options[1].label}</Typography>
      <TextField
        value={answer}
        disabled={disabled}
        type="number"
        inputProps={{
          min: parseInt(options[0].label),
          max: parseInt(options[1].label),
          step: 1,
        }}
        onChange={(e) => handleChoice(e.target.value)}
        sx={{ width: "200px" }}
        variant="outlined"
        size="small"
        error={localError} 
        helperText={localError ? "Valore non valido" : ""} 
      />
    </Box>
  );
};

export default QuantitySelect;
