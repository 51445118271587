import { Button } from "@mui/material";
import React from "react";
import useLogout from "../../../../hooks/auth/useLogout";
import { isMobile } from "react-device-detect";

const LogoutBtn = () => {
  const logout = useLogout();
  const signOut = async () => {
    if (window.confirm("Verrai disconnesso, procedere?")) await logout();
  };
  return (
    <Button fullWidth={(isMobile || window.innerWidth <= 785) && true} onClick={signOut} color="error">
      LOGOUT
    </Button>
  );
};

export default LogoutBtn;
