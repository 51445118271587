import React from "react";

import { isMobile } from "react-device-detect";

import LogoutBtn from "./singleButtons/LogoutBtn";
import ProfileBtn from "./singleButtons/ProfileBtn";
import PharmaciesHomeBtn from "./singleButtons/PharmaciesHomeBtn";
import PharmaciesUploadBtn from "./singleButtons/PharmaciesUploadBtn";
import PharmaciesPromotionsBtn from "./singleButtons/PharmaciesPromotionsBtn";

const PharmaciesNavbar = () => {
  return (
    <>
      <PharmaciesHomeBtn />
      <PharmaciesPromotionsBtn />
      <PharmaciesUploadBtn />
      <ProfileBtn to="/profile"/>
      {isMobile && <LogoutBtn />}
    </>
  );
};

export default PharmaciesNavbar;
