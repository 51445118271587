import React from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function createData(
  id,
  value
  //colonne tabella
) {
  return {
    id,
    value,
    //colonne tabella
  };
}

const RolesList = (roles, userType, frontendParams, active) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [searchParams, setSearchParams] = useSearchParams();

  const renderTableHeader = (header, title) => {
    if (
      frontendParams.order_key === header &&
      frontendParams.order_desc === "true"
    )
      return (
        <React.Fragment>
          <ArrowDropDownIcon />
          {title}
        </React.Fragment>
      );
    else if (
      frontendParams.order_key === header &&
      frontendParams.order_desc === "false"
    )
      return (
        <React.Fragment>
          <ArrowDropUpIcon />
          {title}
        </React.Fragment>
      );
  };

  const handleRecordState = async (recordId, state) => {
    try {
      const result = await axiosPrivate.post(`/Roles/${state}_record`, {
        id: recordId,
      });

      if (result) {
        setSearchParams(frontendParams);
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  const handleRecordDelete = async (recordId) => {
    try {
      if (
        window.confirm("Il record verrà eliminato definitivamente. Procedere?")
      ) {
        const result = await axiosPrivate.delete(`/Roles/delete/${recordId}`);

        if (result) {
          setSearchParams(frontendParams);
        }
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  const rows = [];

  for (let i = 0; i < roles?.length; i++) {
    rows.push(createData(roles[i].id, roles[i].value, roles[i].active_state));
  }
  console.log(rows);
  const columns = [
    // {
    //   field: "created_at",
    //   headerName: "Creato il",
    //   flex: 1,
    //   minWidth: 90,
    //   maxWidth: 90,
    //   sortable: false,
    //   renderHeader: (param) => {
    //     return renderTableHeader(param.field, param.colDef.headerName);
    //   },
    //   renderCell: (params) => {
    //     const date = new Date(params.value);
    //     const formattedDate = date.toLocaleDateString();
    //     const formattedTime = date.toLocaleTimeString();

    //     return (
    //       <Box
    //         minWidth="85px"
    //         sx={{ borderRight: "1px solid #ddd", fontSize: 12, ml: -1 }}
    //       >
    //         📆 {formattedDate}
    //         <br />
    //         🕒 {formattedTime}
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
      // minWidth: 700,
      // maxWidth: 700,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        return (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            <b>{params.value}</b>
          </span>
        );
      },
    },
    {
      flex: 1,
      align: "right",
      sortable: false,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <Tooltip name="Dettaglio" followCursor arrow>
              <Button
                size="small"
                sx={{ minWidth: 0.1 }}
                onClick={(e) => {
                  navigate(
                    "/" + userType + "/practices/detail/" + params.row.id
                  );
                }}
              >
                <SearchIcon fontSize="large" />
              </Button>
            </Tooltip>
            {active ? (
              <Tooltip name="Disattiva" followCursor arrow>
                <Button
                  size="small"
                  sx={{ minWidth: 0.1 }}
                  color="error"
                  onClick={() => handleRecordState(params.row.id, "suspend")}
                >
                  <ThumbDownAltIcon fontSize="large" />
                </Button>
              </Tooltip>
            ) : (
              <React.Fragment>
                <Tooltip name="Attiva" followCursor arrow>
                  <Button
                    size="small"
                    sx={{ minWidth: 0.1 }}
                    color="success"
                    onClick={() => handleRecordState(params.row.id, "reactive")}
                  >
                    <ThumbUpAltIcon fontSize="large" />
                  </Button>
                </Tooltip>
                <Tooltip name="Elimina definitivamente" followCursor arrow>
                  <Button
                    size="small"
                    sx={{ minWidth: 0.1 }}
                    color="error"
                    onClick={() => handleRecordDelete(params.row.id)}
                  >
                    <DeleteOutlineIcon fontSize="large" />
                  </Button>
                </Tooltip>
              </React.Fragment>
            )}
            {/* {(userType === "admin" || userType === "controller") && (
              <Tooltip name="Modifica" followCursor arrow>
                <Button
                  size="small"
                  sx={{ minWidth: 0.1, mx: 3 }}
                  color="warning"
                  // onClick={(e) => onButtonClick(e, params.row)}
                >
                  <DriveFileRenameOutlineIcon fontSize="large" />
                </Button>
              </Tooltip>
            )}
            {userType === "admin" && (
              <Tooltip name="Elimina" followCursor arrow>
                <Button
                  size="small"
                  sx={{ minWidth: 0.1 }}
                  color="error"
                  // onClick={(e) => onButtonClick(e, params.row)}
                >
                  <DeleteOutlineIcon fontSize="large" />
                </Button>
              </Tooltip>
            )} */}
          </React.Fragment>
        );
      },
    },
  ];
  if (userType === "admin") {
    columns.unshift({
      field: "id",
      headerName: "ID",
      maxWidth: 75,
      minWidth: 75,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
    });
  }

  return { rows: rows, columns: columns };
};

export default RolesList;
