import React, { useEffect, useState } from "react";

import { isMobile } from "react-device-detect";

import { Box, Switch, TextField, Typography } from "@mui/material";

const QuantitySelect = ({ componentId, saveComponent }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [obligatory, setObligatory] = useState(false);
  const [options, setOptions] = useState(["", ""]);
  const [minQuantity, setMinQuantity] = useState("");
  const [maxQuantity, setMaxQuantity] = useState("");

  const handleAddOption = (value, type) => {
    if (type === "min") {
      setOptions((prevOptions) => [value.trim(), prevOptions[1]]);
    } else if (type === "max") {
      setOptions((prevOptions) => [prevOptions[0], value.trim()]);
    }
  };

  const handleMinQuantityChange = (e) => {
    const value = e.target.value;
    if (value < 0) return;

    setMinQuantity(value);
    handleAddOption(value, "min");
  };

  const handleMaxQuantityChange = (e) => {
    const value = e.target.value;
    if (value < 0) return;

    setMaxQuantity(value);
    handleAddOption(value, "max");
  };

  const handleSave = () => {
    saveComponent(componentId, title, description, options, obligatory);
  };

  useEffect(() => {
    handleSave();
  }, [title, description, options, obligatory]);

  return (
    <>
      <Typography variant="h5"> Quantità</Typography>
      Crea un elemento che contiene una quantità selezionabile
      <TextField
        fullWidth
        label="Titolo"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        style={{ marginTop: 8, marginBottom: 8 }}
      />
      <TextField
        fullWidth
        label="Descrizione"
        multiline
        rows={2}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{ marginTop: 16, marginBottom: 8 }}
      />
      <Box display={"flex"} justifyContent={"center"}>
        <Typography sx={{ mt: 2, mr: 2 }}>
          Quantità minima selezionabile:{" "}
        </Typography>
        <input
          type="number"
          value={minQuantity}
          onChange={handleMinQuantityChange}
          min="0"
          style={{ width: isMobile ? "125px" : "block"}}
        />
      </Box>
      <Box display={"flex"} justifyContent={"center"} sx={{ mt: 1 }}>
        <Typography sx={{ mt: 2, mr: 2 }}>
          Quantità massima selezionabile:{" "}
        </Typography>
        <input
          type="number"
          value={maxQuantity}
          onChange={handleMaxQuantityChange}
          min="0"
          style={{ width: isMobile ? "125px" : "block"}}
        />
      </Box>
      <Typography sx={{ mt: 3 }}>
        È obbligatorio rispondere a questa domanda?
      </Typography>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Typography>No</Typography>
        <Switch onChange={(e) => setObligatory(e.target.checked)} />
        <Typography>Si</Typography>
      </Box>
    </>
  );
};

export default QuantitySelect;
