import * as React from "react";

import PropTypes from "prop-types";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import { Typography } from "@mui/material";
import NavBar from "../../../components/navbar/NavBar";


// import ActiveStateList from "./components/ActiveStateList";
// import InactiveStateList from "./components/InactiveStateList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Users_Table = () => {
  const table = "Users";
  const columns = ["id", "role_id", "email", "password", "active_state"];

const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <NavBar />
      <Typography variant="h4" sx={{ m: 3 }}>
        Tabella {table}
      </Typography>
			<Box sx={{ width: "100%" }} display={"flex"} flexDirection={"column"}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Utenti attivi" {...a11yProps(0)} />
              <Tab label="Utenti disattivi" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {/* <ActiveStateList table={table} columns={columns} /> */}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {/* <InactiveStateList table={table} columns={columns} /> */}
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default Users_Table;