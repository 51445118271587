import { useLocation, useNavigate } from "react-router-dom";

import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const Delete = ({id}) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const table = "Cluster_Types";

  const del = async () => {
    try {
      const response = await axiosPrivate.delete(
        `/${table}/delete?id=${id}`
      );
      if (response) {
        window.alert("Eliminazione avvenuta con sucesso!");
        navigate(0);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/unauthorized", {
          state: { from: location },
          replace: true,
        });
      } else {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };

  return (
    <>
      <IconButton
        onClick={() => {
          const confirmDel = window.confirm(
            `sei sicuro di voler cancellare questa tupla con id ${id} dal databse?`
          );
          if (confirmDel) del();
        }}
      >
        <DeleteIcon color="primary" />
      </IconButton>
    </>
  );
};

export default Delete;