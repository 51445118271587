import { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetElemDataById from "../../../hooks/useGetElemDataById";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
const Update = ({ handleOpen, open, id }) => {
  const table = "Promotions"
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const prev = useGetElemDataById(table, id,  "id");

  const [selectedtypology_id, setSelectedtypology_id] = useState();
  const promotion_typologiesTemp = useGetUnlimitedListOf("Promotion_Typologies", "", "list");
  const promotion_typologiesData = promotion_typologiesTemp.listElements;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

useEffect(() => {
    if (prev) {
      if(prev.typology_id) {
        setSelectedtypology_id(prev.typology_id);
        setValue("typology_id", prev.typology_id);
      }
    }
  }, [prev]);

  const update = async (data) => {
if (!data.typology_id?.trim() || !data.title?.trim()) {
    window.alert("typology_id, title non possono essere vuoti!");
    return;
  }

data.start = dayjs(data.start).format("YYYY-MM-DD HH:mm")
data.end = dayjs(data.end).format("YYYY-MM-DD HH:mm")

    const nullColumns = Object.keys(data)
    .filter(key => data[key] === null || data[key] === "")
    .join("|");

    const deletedData = data;
    delete deletedData.id;
    try {
      const updatedData = {
				id: prev.id,
        new_values: deletedData,
        str_list_null: nullColumns,
      };

      console.log(updatedData);

      const response = await axiosPrivate.put(`/${table}/update`, updatedData);
      if (response) {
        window.alert("Modifica avvenuta con successo!");
        navigate(0);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/unauthorized", {
          state: { from: location },
          replace: true,
        });
      } else {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };

  if (prev.length !== 0) {
    return (
      <>

        <Modal open={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ mb: 2 }}
            >
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Aggiorna l'elemento con id {id} alla tabella {table}
              </Typography>
              <Button
                color="error"
                onClick={() => handleOpen(false, "mod")}
                sx={{
                  maxWidth: "30px",
                  maxHeight: "30px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            {/* Autocomplete per il campo typology_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={promotion_typologiesData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedtypology_id
                  ? promotion_typologiesData.find((promotion) => promotion.id === selectedtypology_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedtypology_id(newValue.id);
                  setValue("typology_id", newValue.id);
                } else {
                  setSelectedtypology_id(null);
                  setValue("typology_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select typology_id"
                  placeholder="typology_id"
                  error={!!errors.typology_id}
                  helperText={
                    errors.typology_id ? "Il campo typology_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
						{Object.entries(prev)
              .filter(([key]) => true && key !== "id" && key !== "typology_id" && key !== "start" && key !== "end")
              .map(([key, value], index) => (
                <TextField
                  key={index}
                  placeholder={key}
                  defaultValue={value}
                  sx={{ width: "50%", m: 1 }}
                  {...register(key)}
                />
              ))}
{/* datetimepicker per campo start */}
<LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="start"
              control={control}
              defaultValue={dayjs(prev.start)}
              render={({ field }) => (
                <DateTimePicker
					sx={{m:1}}
                  label="start"
                  inputFormat="DD/MM/YYYY"
                  value={field.value}
                  onChange={(date) => field.onChange(date)}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "50%", m: 1 }} />
                  )}
                />
              )}
            />
          </LocalizationProvider>

{/* datetimepicker per campo end */}
<LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="end"
              control={control}
              defaultValue={dayjs(prev.end)}
              render={({ field }) => (
                <DateTimePicker
					sx={{m:1}}
                  label="end"
                  inputFormat="DD/MM/YYYY"
                  value={field.value}
                  onChange={(date) => field.onChange(date)}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "50%", m: 1 }} />
                  )}
                />
              )}
            />
          </LocalizationProvider>

            <Button onClick={handleSubmit(update)}>SALVA MODIFICHE</Button>
          </Box>
        </Modal>
      </>
    );
  }
};

export default Update;