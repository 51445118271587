import React, { useEffect, useState } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import useGetListOf from "../../hooks/useGetListOf";

const PromotionsStatusListFilter = ({ onTypeSet, searchParams, user }) => {
  const statusList = useGetListOf("Assignment_States", "", "list");
  const [selectedType, setSelectedType] = useState("");
  const [open, setOpen] = useState(false);

  const handleChange = (value) => {
    setSelectedType(value);
    onTypeSet(value, "assignment_state_id");
  };

  useEffect(() => {
    if (searchParams.size === 0) setSelectedType("");
  }, [searchParams]);

  return (
    <>
      {statusList && statusList.length !== 0 && (
        <FormControl size="small" sx={{ width: "100%" }}>
          <InputLabel>Stato promozione</InputLabel>
          <Select
            value={selectedType}
            label="Stato promozione"
            onChange={(e) => handleChange(e.target.value)}
            open={open}
            onClick={(e) => setOpen(!open)}
          >
            <MenuItem value="">-</MenuItem>
            {statusList.listElements.map((type) => (
              <MenuItem value={type.id}>
                {user === "Farmacia"
                  ? type.description === "Non inviata"
                    ? "Nuova non ancora inoltrata"
                    : type.description === "Inviata"
                    ? "Nuova"
                    : type.description
                  : type.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default PromotionsStatusListFilter;
