import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Divider } from "@mui/material";

function Copyright(props) {
  return (
    <React.Fragment>
      <Divider sx={{ mt: 20, mb: 2 }} />
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
        sx={{ bottom: 10, position: "relative", mx: 1 }}
      >
        {"Alphega"}
        <br />
        <br />
        {"Via Moggia 75A Lavagna, GE"}
        <br />
        {"P.Iva. Nr. 1234567 - C.F. 12345678"}
        <br />
        <br />
        {"Copyright © "}
        <Link color="inherit" href="https://www.alphega-farmacia.it/" target="_blank">
          Alphega
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </React.Fragment>
  );
}

export default Copyright;
