import React from "react";
import NavBar from "../../../../components/navbar/NavBar";
import CreateUser from "../../../../components/users/CreateUser";

const AlphegaCreateUser = () => {
  return (
    <>
      <NavBar type="4719" />
      <CreateUser/>
    </>
  );
};

export default AlphegaCreateUser;
