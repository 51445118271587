import { useNavigate } from "react-router-dom";

import useAuth from "./useAuth";
import { axiosAuth } from "../../api/axios";

const useLogout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const logout = async () => {
    setAuth({});
    try {
      await axiosAuth
        .get("/logout", {
          withCredentials: true,
        })
        .then(navigate("/login"));
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
