import React, { useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";

import LogoPublic from "../../layout/LogoPublic";
import PharmaciesNavbar from "../buttons/PharmaciesNavbar";
import AlphegaNavbar from "../buttons/AlphegaNavbar";

const MobileNav = ({type, userData}) => {
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Box sx={{ mt: 1, display: "flex" }}>
        {open ? (
          <IconButton
            color="error"
            onClick={() => setOpen(!open)}
            sx={{
              position: "absolute",
              justifyContent: "flex-start",
              m: 3,
            }}
          >
            <CloseIcon sx={{ scale: "150%" }} />
          </IconButton>
        ) : (
          <IconButton
            size="large"
            onClick={() => setOpen(!open)}
            sx={{
              position: "absolute",
              justifyContent: "flex-start",
              m: 3,
            }}
          >
            <MenuIcon sx={{ scale: "150%" }} />
          </IconButton>
        )}
        <Box sx={{ width: 150, m: "auto" }}>
          <LogoPublic />
        </Box>
      </Box>
      <Accordion
        expanded={open}
        elevation={open && 20}
        sx={{
          zIndex: 200,
          "&:focus": { outline: "none" },
          "&:before": { height: "0px", outline: "none" },
        }}
      >
        <AccordionSummary
          sx={{
            display: "none",
          }}
        ></AccordionSummary>
        <AccordionDetails
          sx={{
            // backgroundColor: "#f5f5f5",
            mt: 0,
            px: 0,
            pt: 0,
            boxShadow: "inset 0px 1px 5px grey",
          }}
        >
          <Box
            sx={{
              background: "#009534",
              color: "white",
              // boxShadow: "inset 0px 5px 8px #4d4d4d",
            }}
          >
            <Typography fontSize={14} fontWeight={"bold"} sx={{ py: 1 }}>
              Ciao {userData.business_name}
            </Typography>
          </Box>
          {type === "8632" ? (
            <PharmaciesNavbar />
          ) : (
            type === "4719" && <AlphegaNavbar />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default MobileNav;
