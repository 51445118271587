import React from "react";
import ElementsList from "../../../../components/ElementsList";
import { Box, Typography } from "@mui/material";
import NavBar from "../../../../components/navbar/NavBar";

const AlphegaPromotions = () => {
  return (
    <>
      <NavBar type="4719" />
      <Box sx={{ px: "10%", mb: 1 }}>
        <Typography component="h1" variant="h5" sx={{ my: 3, mb: 1 }}>
          Gestione comunicazioni
        </Typography>
      </Box>
      <ElementsList user="alphega_centrale" type="Promotions" variantSearchKeys="complete=true"/>
    </>
  );
};

export default AlphegaPromotions;
